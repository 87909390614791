<div class="main-container">
  <div class="card">
    <div class="card-header">
      <h1>Service Person</h1>
    </div>

    <div class="card-body border-right-primary border-left-primary">
      <form [formGroup]="servicePersonForm" (ngSubmit)="servicePersonData()">
        <div class="form-row">
           <div class="col-md-3 form-group">
            <label for="serviceCategory" style="color: blue"
              >Service Category*</label
            >
            
            <select
              class="form-control"
              id="service-category"
              (change)="onDropDownChange1($event)"
              formControlName="serviceSubCategory"
            >
            <option
            disabled
             value=""
           >
             ALL
           </option>
              <option
                *ngFor="let category of serviceCategories"
                [value]="category"
              >
                {{ category }}
              </option>
            </select>
          </div>
          <div class="col-md-3 form-group">
            <label for="serviceCategory" style="color: blue"
              >Service SubCategory*</label
            >
            
            <select
              
              class="form-control"
              id="serviceSubCategory"
              formControlName="serviceCategory"
            >
            <option
               disabled
                value=""
              >
                ALL
              </option>
              <option
                *ngFor="let category of serviceSubCategories"
                [value]="category"
              >
                {{ category }}
              </option>
            </select>
          </div>
          <div class="col-md-3 form-group">
            <label for="userEmail" style="color: blue">Email</label>
            <input
              type="text"
              class="form-control"
              id="userEmail"
              name="userEmail"
              formControlName="userEmail"
            />
          </div>
          <div class="col-md-3 form-group">
            <label for="userMobile" style="color: blue">Mobile</label>
            <input
              type="text"
              class="form-control"
              id="userMobile"
              name="userMobile"
              formControlName="userMobile"
            />
          </div>
          <div class="col-md-3 form-group">
            <label for="fromDate" style="color: blue"
              > From Date</label
            >
            <input
              type="date"
              class="form-control"
              id="fromDate"
              name="fromDate"
              formControlName="fromDate"
            />
          </div>
          <div class="col-md-3 form-group">
            <label for="toDate" style="color: blue"
              >To Date</label
            >
            <input
              type="date"
              class="form-control"
              id="toDate"
              name="toDate"
              formControlName="toDate"
            />
          </div>
          <div class="col-md-3 form-group">
            <label for="userState" style="color: blue">State</label>
            <input
              type="text"
              class="form-control"
              id="userState"
              name="userState"
              formControlName="userState"
            />
          </div>
          <div class="col-md-3 form-group">
            <label for="request-status" style="color: blue"
              >Status*</label
            >
            <select
              class="form-control"
              id="status"
              formControlName="status"
            >
              <option value="INACTIVE">INACTIVE</option>
              <option value="active">ACTIVE</option>
            </select>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col-md-12 text-center">
            <button type="submit" class="btn btn-primary">Search</button>
          </div>
        </div>
      </form>
    </div>
    
  </div>
  <div class="table-container">
    <table class="table table-bordered table-striped mt-3">
      <div class="d-flex justify-content-center" *ngIf="bool">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <thead>
        <tr>
          <th>Service Person Name</th>
          <th>Mobile</th>
          <th>Email</th>
          <th>City</th>
          <th>District</th>
          <th>State</th>
          <th>Availability</th>
        </tr>
      </thead>
      <tbody>
        <!-- Add table data here -->
        <tr
          *ngFor="
            let item of tableData
               | paginate: {itemsPerPage: 20,currentPage:page,totalItems:totalLength}; let i = index
          "
        >
          <td>{{ item.name }}</td>
          <td>{{ item.mobile }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.district }}</td>
          <td>{{ item.state }}</td>
          <td>
            <span
              [class]="
                'btn btn-sm ' + (item.active ? 'btn-success' : 'btn-danger')
              "
            >
              {{ item.status }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
</div>
