<div class="container emp-profile">
  <form method="post">
    <div class="row">
      <div class="col-md-4">
        <div class="profile-img">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
            alt=""
          />
          <div class="file btn btn-lg btn-primary">
            Change Photo
            <input type="file" name="file" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="profile-head">
          <h1>
            {{ profileData?.name }}
          </h1>

          <!-- <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                          <a class="nav-link active" id="home-tab" data-toggle="tab"  role="tab" aria-controls="home" aria-selected="true">About</a>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" id="profile-tab" data-toggle="tab"  role="tab" aria-controls="profile" aria-selected="false">Timeline</a>
                      </li>
                  </ul> -->
          <div class="col-md-6">
            <div class="tab-content profile-tab" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label>Email</label>
                  </div>
                  <div class="col-md-6">
                    <p> {{ profileData?.email }} </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>Address</label>
                  </div>
                  <div class="col-md-6">
                    <p> {{ profileData?.address}} </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>Phone</label>
                  </div>
                  <div class="col-md-6">
                    <p> {{ profileData?.mobile }} </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>Town</label>
                  </div>
                  <div class="col-md-6">
                    <p> {{profileData?.city}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>Pincode</label>
                  </div>
                  <div class="col-md-6">
                    <p>  {{ profileData?.pincodeNo}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>District</label>
                  </div>
                  <div class="col-md-6">
                    <p>{{ profileData?.district }} </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>State</label>
                  </div>
                  <div class="col-md-6">
                    <p>{{ profileData?.state }} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <input
          type="submit"
          class="btn btn-primary"
          name="btnAddMore"
          value="Edit Profile"
          routerLink="/sp-dashboard/sp-update-profile"
        />
      </div>
    </div>

  </form>
</div>
