

<div class="container-fluid">


  <div class="d-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0">Dashboard</h1>
      <!-- <a class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
              class="fas fa-download fa-sm text-white-50"></i> Generate Report</a> -->
  </div>


  <div class="row">

      <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                  <div class="row">
                      <div class="col">
                          <div class="earnings font-weight-bold" style="font-size: 1.2em;">
                              Service Persons</div>
                          <!-- <div class="h5 mb-0 font-weight-bold text-gray-800">$40,000</div> -->
                      </div>
                      <div class="col-auto">
                          <i class="fas fa-calendar icon-calender"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-success shadow h-100 py-2">
              <div class="card-body">
                  <div class="row align-items-center">
                      <div class="col mr-2">
                          <div class="earnings font-weight-bold" style="font-size: 1.2em;">
                              Service Request <span>
                                <!-- <i class="fa fa-user-o" aria-hidden="true"></i> -->
                                <img src="assets/images/new_icon1.png" class="img-fluid" alt="Your Image" style="width: 24px; height: 24px;">
                              </span></div>
                          <!-- <div class="h5 mb-0 font-weight-bold text-gray-800">$215,000</div> -->
                      </div>
                      <div class="col-auto">
                          <i class="fas fa-dollar-sign icon-calender"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>


      <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-info shadow h-100 py-2">
              <div class="card-body">
                  <div class="row align-items-center">
                      <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
                          </div>
                          <div class="row no-gutters align-items-center">
                              <div class="col-auto">
                                  <!-- <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div> -->
                              </div>
                              <div class="col">
                                  <div class="progress progress-sm mr-2">
                                      <div class="progress-bar bg-info" role="progressbar"
                                          style="width: 50%" aria-valuenow="50" aria-valuemin="0"
                                          aria-valuemax="100"></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-auto">
                          <i class="fas fa-clipboard-list icon-calender"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-warning shadow h-100 py-2">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                          <div class="earnings">
                              Pending Requests</div>
                          <!-- <div class="h5 mb-0 font-weight-bold text-gray-800">18</div> -->
                      </div>
                      <div class="col-auto">
                          <i class="fas fa-comments icon-calender"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="row">


    <div class="col-xl-8 col-lg-7">
        <div class="card shadow mb-4">

            <div
                class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Earnings Overview</h6>
                <div class="dropdown no-arrow">
                    <a role="button" id="dropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v  text-gray-400"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right shadow "
                        aria-labelledby="dropdownMenuLink">
                        <div class="dropdown-header">Dropdown Header:</div>
                        <a class="dropdown-item">Action</a>
                        <a class="dropdown-item">Another action</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item">Something else here</a>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="chart-area">
                    <canvas id="myAreaChart"></canvas>
                </div>
            </div>
        </div>
    </div>


    <div class="col-xl-4 col-lg-5">
        <div class="card shadow mb-4">

            <div
                class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Revenue Sources</h6>
                <div class="dropdown no-arrow">
                    <a class="dropdown-toggle" role="button" id="dropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                        aria-labelledby="dropdownMenuLink">
                        <div class="dropdown-header">Dropdown Header:</div>
                        <a class="dropdown-item">Action</a>
                        <a class="dropdown-item">Another action</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item">Something else here</a>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="chart-pie pt-4 pb-2">
                    <canvas id="myPieChart"></canvas>
                </div>
                <div class="mt-4 text-center small">
                    <span class="mr-2">
                        <i class="fas fa-circle text-primary"></i> Direct
                    </span>
                    <span class="mr-2">
                        <i class="fas fa-circle text-success"></i> Social
                    </span>
                    <span class="mr-2">
                        <i class="fas fa-circle text-info"></i> Referral
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

</div>





