
<div class="container mt-4 d-flex justify-content-center">


  <div class="col-md-7 login-form card">
          <div class="auctionFormRight" style="margin-top: 0px;">
              <h3 class="aucformTitle text-center">Add Service Category For Your Home Services</h3>
              <form [formGroup]="addServiceCategoryForm" (ngSubmit)="addServiceCategorySubmit()">


                  <!-- Assets Category -->
                  <div class="form-group row mb-3">
                    <label for="Category" class="col-md-2 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">Category</label>
                    <div class="col-md-10">

                        <input type="text" class="form-control" name="category" placeholder="Enter Category" required formControlName="category">
                    </div>
                </div>

                <!-- Assets Sub-Category -->
                <div class="form-group row mb-3">
                  <label for="SubCategory" class="col-md-2 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">Sub-Category</label>
                  <div class="col-md-10">
                      
                      <input type="text" class="form-control" name="subcategory" placeholder="Enter Category" required formControlName="subcategory">
                  </div>
              </div>

                  <div class="col-md-10 offset-md-2 d-flex justify-content-center">
                      <button type="submit" class="btn btn-primary" style="width: 120px; background-color: #18aedf; color: white; font-size: 17px;">Add</button>
                  </div>

              </form>
          </div>
  </div>
</div>


