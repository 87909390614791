<div class="container mt-4 d-flex justify-content-center">
      <div class="auctionMainForm card col-md-6 login-form">
          <h3 class="aucformTitle text-center">ADMIN LOGIN</h3>
          <form [formGroup]="formData" (ngSubmit)="adminLogin()">
            <div class="aucformFieldsBox col-md-12 col-xs-12" style=" padding: 0 0;">
              <div class="col-md-12 col-sm-12">
              <label style="width: 100%;font-size: 17px;color: #020202; font-family: sans-serif;" class="mb-2">Email/Mobile<span style="color: red">*</span></label>
            <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-user"></i></span>
            <input class="form-control" type="text" placeholder="Enter Email Address or Mobile Number" name="email" formControlName="username" required>

              </div>


              </div>
            </div>
            <div class="aucformFieldsBox col-xs-12 col-xs-12" style=" margin: 13px 0; padding: 0 0;">

              <div class="col-md-12 col-sm-12">
               <label style="width: 100%;font-size: 17px;color: #020202; font-family: sans-serif;"  class="mb-2">Password<span style="color: red">*</span></label>
             <div class="input-group">
             <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                <input class="form-control" style=" border-radius: 0px 4px 4px 0px;" type="password" name="password" placeholder="Enter Password" formControlName="password" required>

               </div>
               </div>
             </div>

             <div class="aucformFieldsBox col-md-12 col-xs-12">
              <div class="col-md-12 col-xs-12 text-center">
              <br>
            <input class="btn btn-success" type="submit" value="LOG IN" [disabled]="!formData.valid"  style="width: 100px;">
              </div>

            </div>
            <div  class="aucformFieldsBox col-xs-12 col-md-4 mb-2" >
              <a style="color: #18aedf; text-decoration: underline;" routerLink="/admin-forgot-password">Forgot Password</a>
            </div>

          </form>

      </div>
  </div>




