<div class="container-fluid mt-4 d-flex justify-content-center">


  <div class="col-md-7 login-form card">
    <div class="auctionFormRight" style="margin-top: 0px;">
      <h3 class="aucformTitle text-center">Update Service Charges</h3>
      <form [formGroup]="addAssetsForm" (ngSubmit)="addAssetsSubmit()">
        <div class="form-group row mb-3">
          <label for="city" class="col-md-4 control-label"
            style="font-size: 17px; color: #020202; font-family: sans-serif;">Service Category</label>
          <div class="col-md-8">
            <input type="text" readonly class="form-control" [(ngModel)]="serviceCategory"
              formControlName="serviceCategory">
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="subcategory" class="col-md-4 control-label"
            style="font-size: 17px; color: #020202; font-family: sans-serif;">Service Sub-Category</label>
          <div class="col-md-8">
            <select id="subcategory" class="form-control" [(ngModel)]="selectedAssetSubCat" placeholder="ALL"
              formControlName="subcategory">
              <option value="" disabled selected>Select</option>
              <option *ngFor="let assetSubCat of assetSubCats" [value]="assetSubCat">{{ assetSubCat }}</option>
            </select>

          </div>
        </div>
        <div class="form-group row mb-3">
          <label for="assetBrand" class="col-md-4 control-label"
            style="font-size: 17px; color: #020202; font-family: sans-serif;">Service Brand</label>
          <div class="col-md-8">
            <select id="brand" class="form-control" [(ngModel)]="selectedAssetBrand" placeholder="ALL" required
              formControlName="brand">
              <option value="" disabled selected>Select</option>
              <option *ngFor="let assetBrand of assetBrands" [value]="assetBrand">{{ assetBrand }}</option>
            </select>
          </div>
        </div>
        <div class="form-group row mb-3">
          <label for="assetModel" class="col-md-4 control-label"
            style="font-size: 17px; color: #020202; font-family: sans-serif;">Service Model</label>
          <div class="col-md-8">
            <select id="model" class="form-control" [(ngModel)]="selectedAssetModel" placeholder="ALL" required
              formControlName="model">
              <option value="" disabled selected>Select</option>
              <option *ngFor="let assetModel of assetModels" [value]="assetModel">{{ assetModel }}</option>
            </select>
          </div>
        </div>

        <!-- Location Field -->

        <div class="form-group row mb-3">
          <label for="city" class="col-md-4 control-label"
            style="font-size: 17px; color: #020202; font-family: sans-serif;">Location</label>
          <div class="col-md-8">
            <input type="text" class="form-control" name="location" placeholder="Enter Your Location" required
              formControlName="location" #placesRef="ngx-places" ngx-gp-autocomplete [options]='options'
              (onAddressChange)="handleAddressChange($event)">
          </div>
        </div>

        <!-- Street Field -->

        <div class="form-group row mb-3">
          <label for="serviceCharge" class="col-md-4 control-label"
            style="font-size: 17px; color: #020202; font-family: sans-serif;">Service Charge</label>
          <div class="col-md-8">
            <input type="text" class="form-control" name="serviceCharge" placeholder="Enter Service Charge" required
              formControlName="serviceCharge">
          </div>
        </div>


        <div class="col-md-8 offset-md-2 d-flex justify-content-center">
          <button type="submit" class="btn btn-primary"
            style="width: 120px; background-color: #18aedf; color: white; font-size: 17px;">Update</button>
        </div>

      </form>
    </div>
  </div>
</div>