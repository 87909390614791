import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-pannel',
  templateUrl: './dashboard-pannel.component.html',
  styleUrls: ['./dashboard-pannel.component.css']
})
export class DashboardPannelComponent {

}
