
<div class="privacy-policy">
<h1>Privacy Policy</h1>
<h2>mekanik.in - PRIVACY POLICY</h2>
<p>We ask that you read this privacy promise carefully as it contains important information. It outlines your rights and how to contact us and other relevant organizations in the event you have a complaint.</p>
<p>We at Kosuriers take great care to ensure the highest standards for the customer information, personal data and transactions provided by you. Disclaimer- Our privacy policy is subject to change at any time.</p>

<h2>DATA STORAGE LOCATION</h2>
<p>We collect both Personal Information and non-personally identifiable information through the Site in order to help us to learn more about how our visitors use the Site, to measure areas of interest, and to provide our users with important information on our network. Collection of information allows us to build features and functionality that will enhance your experience. You are not obligated to provide us with Personal Information in order to use the Site. However, we do require that you register and provide certain Personal Information in order to send us inquiries, to receive information about, and to receive newsletters and other special offers.</p>

<h2>WHO HAS ACCESS TO YOUR DATA</h2>
<p>If you are not a registered client for our site, there is no personal information we can retain or view regarding yourself.</p>
<ul>
  <li>Our system administrators.</li>
  <li>Our supporters when they (in order to provide support) need to get the information about the client accounts and access.</li>
</ul>

<h2>COOKIES</h2>
<p>This site uses cookies – small text files that are placed on your machine to help the site provide a better user experience. In general, cookies are used to retain user preferences, store information for things like shopping carts, and provide anonymized tracking data to third party applications like Google Analytics. Cookies generally exist to make your browsing experience better. However, you may prefer to disable cookies on this site and on others. The most effective way to do this is to disable cookies in your browser. We suggest consulting the help section of your browser.</p>

<p>This Privacy Policy applies to Websites, App and Services that are owned and operated by KOSURI ENGINEERS PRIVATE LIMITED referred as kosuriers from now on. We do not exercise control over the sites that may be displayed as search results or links from within the Services. These other sites may place their own cookies or other files on the Users’ computer, collect data or solicit personal information from the Users, for which kosuriers is not responsible or liable. Accordingly, kosuriers does not make any representations concerning the privacy practices or policies of such third parties or terms of use of such websites, nor does kosuriers guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages or other materials available on such websites. The inclusion or exclusion does not imply any endorsement by kosuriers of the website, the website's provider, or the information on the website. If you decide to visit a third-party website linked to the Website, you do this entirely at your own risk. Kosuriers encourages the User to read the privacy policies of that website.</p>

<p>Kosuriers may periodically ask users to complete surveys asking about their experiences with features of the Websites, App and Services. Our surveys may ask visitors for demographic information such as age, gender, and education. We use survey information for evaluation and quality improvement purposes, including helping Kosuriers to improve information and services offered. In addition, users giving feedback may be individually contacted for follow-up due to concerns raised during the course of such evaluation. Demographic information and Web log data may be stored for future evaluation and quality improvement activities.</p>

<p>Comments or questions sent to us using email or secure messaging forms will be shared with our employees and health care professionals who are most able to address the comment or question. We will archive your messages once we have made our best effort to provide you with a complete and satisfactory response. Some of our services such as our automated appointment selection and prescription refill for Kosuriers generated prescriptions interact directly with other Kosuriers data systems. Data about your transaction may be stored in these systems, and available to people who test and support these systems. When you use a service on the Websites or the App to interact directly with Kosuriers professionals, some information you provide may be documented in your medical record, and available for use to guide your treatment as a patient.</p>

<p>Our Websites and the App may include social media Features, such as the Facebook button. These Features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the Feature to function properly. Your interactions with these Features are governed by the privacy statement of the company providing them.</p>

<p>Kosuriers takes your right to privacy very seriously and other than as specifically stated in this privacy Policy, will only disclose your personal information in the event it is required to do so by law, rule, regulation, law enforcement agency, governmental official, legal authority or similar requirements or when Kosuriers, in its sole discretion, deems it necessary in order to protect its rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk, or to enforce or apply the Terms of Use.</p>

<p>No sensitive personal data or information is automatically collected by Kosuriers from any casual visitors of this website, who are merely perusing the site.</p>

<p>You are not a casual visitor if you have willingly submitted any personal data or information to Kosuriers through any means, including email, post or through the registration process on the Website or App. All such visitors will be deemed to be, and will be treated as, Users for the purposes of this Privacy Policy, and in which case, all the statements in this Privacy Policy apply to such persons.</p>

<h2>Contact Us:</h2>
<p>If you have any questions about this privacy policy, you can contact us: <a href="mailto:info@kosuriers.com">info@kosuriers.com</a></p></div>