import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiserviceService } from 'src/app/services/apiservice/apiservice.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css']
})
export class DeleteAccountComponent {
reason: any;

constructor(private api:ApiserviceService,private authService:AuthService,private router:Router, public dialogRef: MatDialogRef<DeleteAccountComponent>,){}
  
close(): void {
  this.dialogRef.close();
}
delete(){
  let body={
    reason:this.reason,
    spId:localStorage.getItem('USER_ID')
  }
this.api.deleteAccount(body).subscribe((res:any)=>{
if(res.status==true){
  this.close()
  this.authService.logout();
  this.router.navigateByUrl('');
}
})
  }
}
