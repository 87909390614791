
<div class="container main-container">
  <div class="card card-container">
      <div class="card-header">
          <h1>Service Request</h1>
      </div>
      <div class="card-body border-right-primary border-left-primary">
          <form [formGroup]="serviceRequestForm" >
              <div class="form-row">
                <div class="col-md-3 form-group">
                  <label  style="color: blue;">Service Category</label>
                  <input
        type="text"
        id="qualification"
        name="qualification"
        class="form-control"
        [(ngModel)]="sCategory"
        formControlName="serviceCategory"
        readonly
      />
              </div>
                  <div class="col-md-3 form-group">
                      <label for="service-category" style="color: blue;">Service Sub Category</label>
                      <select class="form-control" id="service-category" formControlName="serviceCategory">
                        <option value="" disabled selected>Select service </option>
                        <option *ngFor="let category of subCategories" [value]="category">{{ category }}</option>
                      </select>
                  </div>
                  <div class="col-md-3 form-group">
                      <label for="location" style="color: blue;">Location</label>
                      <input type="text" class="form-control" id="field3" name="field3" formControlName="location" #placesRef="ngx-places" ngx-gp-autocomplete [options]='options' (onAddressChange)="handleAddressChange($event)">
                  </div>
                  <div class="col-md-3 form-group">
                    <label for="email" style="color: blue;">Email</label>
                    <input type="text" class="form-control" id="email" name="email" formControlName="email" >
                  </div>
                  <div class="col-md-3 form-group">
                    <label for="assetId" style="color: blue;">Asset Id</label>
                    <!-- <input type="text" class="form-control" id="assetId" name="assetId" formControlName="assetId" > -->
                    <select class="form-control" id="assetId" formControlName="assetId">
                      <option value="" disabled selected>Select AssetId </option>
                      <option *ngFor="let category of assetIds" [value]="category">{{ category }}</option>
                    </select>
                  </div>

                  <div class="col-md-3 form-group">
                    <label for="fromDate" style="color: blue"
                      >From Date</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      id="fromDate"
                      name="fromDate"
                      formControlName="fromDate"
                    />
                </div>
                <div class="col-md-3 form-group">
                  <label for="toDate" style="color: blue"
                    >To Date</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    id="toDate"
                    name="toDate"
                    formControlName="toDate"
                  />
              </div>
                  <div class="col-md-3 form-group">
                      <label for="status" style="color: blue;">Request Status*</label>
                      <select class="form-control" id="status" formControlName="status">
                          <option value="NEW">NEW</option>
                          <option value="approved">Approved</option>
                          <option value="rejected">Rejected</option>
                      </select>
                  </div>
                  <div class="col-md-3 form-group">
                    <label for="field3" style="color: blue;">Phone Number*</label>
                    <input type="text" class="form-control" id="field3" name="field3" formControlName="phoneNo">
                </div>
              </div>

              <div class="form-row mt-3">
                <div class="col-md-12 text-center">
                    <button type="submit" class="btn btn-primary" (click)="search()">Search</button>
                </div>
            </div>
          </form>
      </div>
  </div>
   <!-- table -->
   <div class="table-container">

      <table class="table table-bordered table-striped mt-3">
        <div class="d-flex justify-content-center" *ngIf="bool">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
   <thead>
    <tr>
       <th scope="col">Request._Id.</th>
       <th scope="col">Asset ID</th>
       <th scope="col">Service Name</th>
       <th scope="col">Service Date</th>
       <th scope="col">Request Date</th>
       <th scope="col">Location</th>
       <th scope="col">Description</th>
       <th scope="col">Status</th>
      <!-- <th scope="col">Action</th> -->
    </tr>
   </thead>
<tbody>
<!-- Add table data here -->
<tr *ngFor="let item of tableData | paginate: {itemsPerPage: 20,currentPage:page,totalItems:totalLength}; let i = index">
  <td>{{ item.requestId }}</td>
  <td>{{ item.assetId}}</td>
  <td>{{ item.serviceName }}</td>
  <td>{{ item.serviceDateDb | date: 'dd/MM/yyyy' }}</td>
  <td>{{ item.serviceRequestDate | date: 'dd/MM/yyyy' }}</td>
  <td>{{ item.location }}</td>
  <td>{{ item.description }}</td>
  <td>{{ item.status}}</td>

</tr>
</tbody>

      </table>

      <div class="pagination">
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>

   </div>
</div>
