

<div class="container main-container">
    <div class="card card-container">
        <div class="card-header">
            <h1>Show Rental Data</h1>
        </div>
        <div class="card-body border-right-primary border-left-primary">
          <form>
            <div class="row">
                <div class="col-md-3 form-group">
                    <label for="location" style="color: blue;">Location</label>
                    <input type="text" class="form-control" id="field3" name="field3" formControlName="location" #placesRef="ngx-places" ngx-gp-autocomplete [options]='options' (onAddressChange)="handleAddressChange($event)">
                </div>
              </div>
           
            <div class="form-row mt-3">
              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-primary" (click)="search()">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
    </div>
     <!-- table -->
     <div class="table-container">
  
    <table class="table table-bordered table-striped mt-3">
      <div class="d-flex justify-content-center" *ngIf="bool">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
     <thead>
      <tr>
         <th scope="col">Asset Id</th>
         <th scope="col">User Id</th>
         <th scope="col">IsAvail Rent</th>    
         <th scope="col">Amount PerDay</th>
         <th scope="col">Amount per30days</th>
         <th scope="col">pickup</th>
         <th scope="col">Available Location</th>
         <th scope="col">Delivery</th>
         <th scope="col">EDIT</th>
      </tr>
     </thead>
  <tbody>
  
  <tr *ngFor="let item of assetCategoryData | paginate: {itemsPerPage:20,currentPage:page,totalItems:totalLength}; let i = index">
    <td>{{ item.assetId}}</td>
    <td>{{ item.userId}}</td>
    <td>{{ item.isAvailRent}}</td>
    <td>{{ item.amountPerDay }}</td>
    <td>{{ item.amountper30days }}</td>
    <td>{{ item.pickup }}</td>
    <td>{{ item.availableLocation }}</td>
    <td>{{ item.delivery }}</td>
    <td>
      <button class="btn btn-primary" (click)="editRental(item)">Edit</button>
    </td>
  
  </tr>
  </tbody>
  
        </table>
  
        <div class="pagination">
          <pagination-controls (pageChange)="page = $event"></pagination-controls>
        </div>
  
     </div>
  </div>
