<!-- src/app/password-reset/password-reset.component.html -->
<div class="container-fluid mt-4 d-flex justify-content-center">
    <div class="col-md-7 login-form card">
      <div class="auctionFormRight" style="margin-top: 0px;">
        <h3 class="aucformTitle text-center">Reset Password</h3>
        <form [formGroup]="updateForm" (ngSubmit)="updateSubmitForm()">
  
          <!-- Old Password -->
          <div class="form-group row mb-3 position-relative">
            <label for="oldPassword" class="col-md-4 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">Old Password</label>
            <div class="col-md-8 position-relative">
              <input [type]="isOldPasswordVisible ? 'text' : 'password'" class="form-control" name="oldPassword" placeholder="Enter Your Old Password" required formControlName="oldPassword">
              <i class="fa position-absolute"
                 [ngClass]="{'fa-eye': !isOldPasswordVisible, 'fa-eye-slash': isOldPasswordVisible}"
                 (click)="toggleOldPasswordVisibility()"
                 style="top: 30%; right: 20px; transform: translateY(-50%); cursor: pointer;"
                 aria-hidden="true"></i>
            </div>
          </div>
  
          <!-- New Password -->
          <div class="form-group row mb-3 position-relative">
            <label for="newPassword" class="col-md-4 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">New Password</label>
            <div class="col-md-8 position-relative">
              <input [type]="isNewPasswordVisible ? 'text' : 'password'" class="form-control" name="newPassword" placeholder="Enter Your New Password" required formControlName="newPassword">
              <i class="fa position-absolute"
                 [ngClass]="{'fa-eye': !isNewPasswordVisible, 'fa-eye-slash': isNewPasswordVisible}"
                 (click)="toggleNewPasswordVisibility()"
                 style="top: 30%; right: 20px; transform: translateY(-50%); cursor: pointer;"
                 aria-hidden="true"></i>
            </div>
          </div>
  
          <!-- Confirm Password -->
          <div class="form-group row mb-3 position-relative">
            <label for="confirmPassword" class="col-md-4 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">Confirm Password</label>
            <div class="col-md-8 position-relative">
              <input [type]="isConfirmPasswordVisible ? 'text' : 'password'" class="form-control" name="confirmPassword" placeholder="Enter Your Confirm Password" required formControlName="confirmPassword">
              <i class="fa position-absolute"
                 [ngClass]="{'fa-eye': !isConfirmPasswordVisible, 'fa-eye-slash': isConfirmPasswordVisible}"
                 (click)="toggleConfirmPasswordVisibility()"
                 style="top: 30%; right: 20px; transform: translateY(-50%); cursor: pointer;"
                 aria-hidden="true"></i>
            </div>
          </div>
  
          <div class="col-md-10 offset-md-2 d-flex justify-content-center">
            <button type="submit" class="btn btn-primary" style="width: 120px; background-color: #18aedf; color: white; font-size: 17px;">SUBMIT</button>
          </div>
  
        </form>
      </div>
    </div>
  </div>
  


