<div class="container-fluid1 ">
  <div class="row justify-content-center ">
    <div class="col-12">
      <div class="search-bar header">
        <form [formGroup]="submitForm" class="d-flex w-100 my-2">
          <select class="form-control ml-2" [(ngModel)]="selectedMode" formControlName="mode" (change)="changeMode($event)">
            <option value="centre"  >Service centre</option>
            <option value="rental"  >Rental</option>
            
          </select>
          <select class="form-control ml-2" id="assetSubCat1" [(ngModel)]="selectedCategory" *ngIf="submitForm.value.mode!='rental'" formControlName="assetSubCat1" (change)="onCategoryChange()">
            <option value="" disabled selected>Service Category</option>
            <option *ngFor="let category of serviceCategories" [value]="category">{{ category }}</option>
          </select>
          <select class="form-control ml-2" id="assetSubCat1" *ngIf="submitForm.value.mode=='rental'" [(ngModel)]="selectedCategory" formControlName="assetSubCat1" (change)="onCategoryChange()">
            <option value="" disabled selected>Rental Category</option>
            <option *ngFor="let category of assetCats" [value]="category">{{ category }}</option>
          </select>
          <select class="form-control ml-2" id="assetSubCat" [(ngModel)]="selectedSubCategory" *ngIf="submitForm.value.mode!='rental'" formControlName="assetSubCat">
            <option value="" disabled selected>Service Type</option>
            <option *ngFor="let subCategory of serviceSubCategories" [value]="subCategory">{{ subCategory }}</option>
          </select>
          <select class="form-control ml-2" id="assetSubCat" [(ngModel)]="selectedSubCategory" *ngIf="submitForm.value.mode=='rental'" formControlName="assetSubCat">
            <option value="" disabled selected>Sub Category</option>
            <option *ngFor="let subCategory of assetSubCats" [value]="subCategory">{{ subCategory }}</option>
          </select>
          <input
            class="form-control ml-2"
            type="text"
            id="field3"
            name="field3"
            formControlName="Location"
            #placesRef="ngx-places"
            ngx-gp-autocomplete
            [options]="options"
            (onAddressChange)="handleAddressChange($event)"
            placeholder="LOCATION"
          />
          <button [disabled]="!isFormValid" type="button" class="btn btn-success ml-2" (click)="onSubmitRequestForm()">
            <i (click)="onSubmitRequestForm()" class="fa fa-search"></i>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isBool" class="mt-5  responsive-div innerContent">
  
    {{spServices.length}} Search results for Service Type <span class="font-weight-bold h8 font"> {{submitForm.value.assetSubCat}}</span>  <span *ngIf="submitForm.value?.Location" class="font-weight-bold h8 font">  and location {{choosenLocation}} </span>
  
  
</div>

<app-home1  *ngIf="isBool" [spServices]="spServices"></app-home1>

<section container-fluid *ngIf="!isBool">
  <div class="full-width-container position-relative" style="top: 100px;">
    <img src="assets/images/ravi6.jpg" class="img-fluid w-100" alt="..." />
    <div class="text-container text-center">
      <div class="mt-4 heading">Available On Call</div>
      <div class="mt-4 subheading">
        ELECTRICALS - ELECTRONICS - MECHANICAL - AUTO <br />
        ALL MAINTAINENCE UNDER ONE UMBRELLA <br />
        GET QUOTES FROM OUR TRUSTED BUSINESS PARTNERS <br />
        GET SERVICE FROM OUR PROFESSIONALS
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="container"  style="top: 70px;">
      <marquee behavior="scroll" direction="left" scrollamount="4">
        We undertake  Maintainence of Electronics, Electrical, Mechanical & Automotive parts 
      </marquee>
    </div>

    <!-- <section style="top: 30px;position:relative">
      <div class="px-5 mt-5" id="civil-section" >
        <h4 class="services-heading p-2 custom-bg">Civil engineering</h4>
      </div>

      <div class="container mt-4">
        <div class="row">
          <div class="col-md-3">
            <div class="card h-100 card-no-border">
              <div class="card-body text-center">
                <i class="fa fa-building-o fa-2x"></i>
                
                <h5 class="card-title mb-4">Building Materials</h5>
                <p class="card-text justify-text">
                  Building material is any material which is used for
                  construction purposes. Many naturally occurring substances,
                  such as clay, rocks, sand, and wood, even twigs and leaves,
                  have been used to construct buildings..
                </p>
              </div>
            
            </div>
          </div>

          <div class="col-md-3">
            <div class="card h-100 card-no-border">
              <div class="card-body text-center">
                <i class="fa fa-deviantart fa-2x"></i>
               
                <h5 class="card-title mb-4">Property Developers</h5>
                <p class="card-text justify-text">
                  Property developers are perhaps best seen as 'conductors'. A
                  Property Developer is likely to engage in a wide range of
                  property related activities - finding the best locations,
                  sourcing funds, obtaining planning..
                </p>
              </div>
              
            </div>
          </div>

          <div class="col-md-3">
            <div class="card h-100 card-no-border">
              <div class="card-body text-center">
                <i class="fa fa-home fa-2x"></i>
               
                <h5 class="card-title mb-4">Plumbing</h5>
                <p class="card-text justify-text">
                  Plumbers spend most of their time with customers or on site,
                  so it's a job of both communication and skill. While strictly
                  a 40-hour-per-week job, many plumbers work overtime,although
                  self-employed plumbers..
                </p>
              </div>
            
            </div>
          </div>

          <div class="col-md-3">
            <div class="card h-100 card-no-border">
              <div class="card-body text-center">
                <i class="fa fa-retweet fa-2x"></i>
               
                <h5 class="card-title mb-4">Electrical</h5>
                <p class="card-text justify-text">
                  An electric light is a device that produces visible light from
                  electric current. It is the most lighting providing interior
                  lighting for buildings and exterior light for evening and
                  nighttime activities ..
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section> -->

   <section style="top: 30px;position:relative">
    <div class="px-5 mt-5 " >
      <h4 class="services-heading p-2 custom-bg">Mechanical</h4>
    </div>
  
      <div class="container mt-4">
      <div class="row">
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
            <img class="single-place-image h-100" src="../../../assets/Service-images/fitters.jpg" alt="service image1">
            <div class="single-place-content">
              <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                  <a href="#" style="text-decoration: none; color: inherit; display: block;">Fitters</a>
              </h2>
            </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
            <img class="single-place-image h-100" src="../../../assets/Service-images/plumber.jpg" alt="service image1">
            <div class="single-place-content">
              <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                  <a href="#" style="text-decoration: none; color: inherit; display: block;">Plumbers</a>
              </h2>
            </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
              <img class="single-place-image h-100" src="../../../assets/Service-images/battery.jpg" alt="service image1">
              <div class="single-place-content">
                <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center; transition: transform 0.3s ease;">
                    <a href="#" style="text-decoration: none; color: inherit; display: block;">Battery</a>
                </h2>
              </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
              <img class="single-place-image h-100" src="../../../assets/Service-images/inverter.jpg" alt="service image1">
              <div class="single-place-content">
                <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                    <a href="#" style="text-decoration: none; color: inherit; display: block;">Inverters</a>
                </h2>
              </div>
            </div>
        </div>
      </div>
    </div>
  
    <div class="px-5 mt-5 ">
      <h4 class="services-heading p-2 custom-bg">Electrical</h4>
    </div>
  
    <div class="container mt-4">
      <div class="row">
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
            <img class="single-place-image h-100" src="../../../assets/Service-images/wiring.jpg" alt="service image1">
            <div class="single-place-content">
              <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                  <a href="#" style="text-decoration: none; color: inherit; display: block;">House Wiring</a>
              </h2>
            </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
            <img class="single-place-image h-100" src="../../../assets/Service-images/generators.jpg" alt="service image1">
            <div class="single-place-content">
              <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                  <a href="#" style="text-decoration: none; color: inherit; display: block;">Generators</a>
              </h2>
            </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
              <img class="single-place-image h-100" src="../../../assets/Service-images/motor winding.jpg" alt="service image1">
              <div class="single-place-content">
                <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center; transition: transform 0.3s ease;">
                    <a href="#" style="text-decoration: none; color: inherit; display: block;">Motor Winiding</a>
                </h2>
              </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
              <img class="single-place-image h-100" src="../../../assets/Service-images/fan service.jpg" alt="service image1">
              <div class="single-place-content">
                <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                    <a href="#" style="text-decoration: none; color: inherit; display: block;">Fan Services</a>
                </h2>
              </div>
            </div>
        </div>
      </div>
    </div>
  
    <div class="px-5 mt-5 ">
      <h4 class="services-heading p-2 custom-bg">Electronics</h4>
    </div>
  
    <div class="container mt-4">
      <div class="row">
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
            <img class="single-place-image h-100" src="../../../assets/Service-images/laptops.jpg" alt="service image1">
            <div class="single-place-content">
              <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                  <a href="#" style="text-decoration: none; color: inherit; display: block;">Laptops</a>
              </h2>
            </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
            <img class="single-place-image h-100" src="../../../assets/Service-images/desktop.jpeg" alt="service image1">
            <div class="single-place-content">
              <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                  <a href="#" style="text-decoration: none; color: inherit; display: block;">Desktops</a>
              </h2>
            </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
              <img class="single-place-image h-100" src="../../../assets/Service-images/printers.jpg" alt="service image1">
              <div class="single-place-content">
                <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center; transition: transform 0.3s ease;">
                    <a href="#" style="text-decoration: none; color: inherit; display: block;">Printers</a>
                </h2>
              </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
              <img class="single-place-image h-100" src="../../../assets/Service-images/mobiles.jpg" alt="service image1">
              <div class="single-place-content">
                <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                    <a href="#" style="text-decoration: none; color: inherit; display: block;">Mobiles</a>
                </h2>
              </div>
            </div>
        </div>
      </div>
    </div>
  
    <div class="px-5 mt-5 ">
      <h4 class="services-heading p-2 custom-bg">Auto</h4>
    </div>
  
    <div class="container mt-4">
      <div class="row">
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
            <img class="single-place-image h-100" src="../../../assets/Service-images/car.jpg" alt="service image1">
            <div class="single-place-content">
              <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                  <a href="#" style="text-decoration: none; color: inherit; display: block;">Car</a>
              </h2>
            </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
            <img class="single-place-image h-100" src="../../../assets/Service-images/bike.jpg" alt="service image1">
            <div class="single-place-content">
              <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                  <a href="#" style="text-decoration: none; color: inherit; display: block;">Bike</a>
              </h2>
            </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
              <img class="single-place-image h-100" src="../../../assets/Service-images/auto.jpg" alt="service image1">
              <div class="single-place-content">
                <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center; transition: transform 0.3s ease;">
                    <a href="#" style="text-decoration: none; color: inherit; display: block;">Auto</a>
                </h2>
              </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-100 card-no-border">
              <img class="single-place-image h-100" src="../../../assets/Service-images/all.jpg" alt="service image1">
              <div class="single-place-content">
                <h2 class="single-place-title" style="background: #fa8f1bb8; padding: 10px 26px; text-align: center;">
                    <a href="#" style="text-decoration: none; color: inherit; display: block;">And Many more</a>
                </h2>
              </div>
            </div>
        </div>
      </div>
    </div> 
      <!-- <div class="px-5 mt-5 ">
      <h4 class="services-heading p-2 custom-bg">Homes Under Constructions</h4>
    </div>
  
      <div class="container mt-4">
      <div class="row">
        <div class="col-md-3">
          <div class="card h-80">
              <div class="card" style="width: 100%;">
                  <img class="card-img-top" src="assets/images/bul1.jpg" alt="Card image cap" style="max-width: 100%;">
                  <div class="card-body">
                      <h6>Brand: consustation</h6>
                      <h6>Model:</h6>
                  </div>
              </div>
          </div>
      </div>
        <div class="col-md-3">
          <div class="card h-80 ">
            <div class="card" style="width: 100%;">
              <img class="card-img-top" src="assets/images/bul2.jpg" alt="Card image cap">
              <div class="card-body">
                <h6>Brand : consustation</h6>
                <h6>Model: </h6>
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-80 card">
            <div class="card" style="width: 100%;">
              <img class="card-img-top" src="assets/images/bul3.jpg" alt="Card image cap">
              <div class="card-body">
                <h6>Brand : consustation</h6>
                <h6>Model: </h6>
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="card h-80">
            <div class="card" style="width: 100%;">
              <img class="card-img-top" src="assets/images/bul4.jpg" alt="Card image cap">
              <div class="card-body">
                <h6>Brand : consustation</h6>
                <h6>Model: </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
   </section> 
  </div>
</section>
