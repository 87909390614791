
<div class="container mt-4 d-flex justify-content-center">


  <div class="col-md-7 login-form card">
          <div class="auctionFormRight" style="margin-top: 0px;">
              <h3 class="aucformTitle text-center">Edit Assets Category For Your Home Services</h3>
              <form [formGroup]="addAssetsCategoryForm" (ngSubmit)="editAssetCategoryForm()">


                 <!-- Assets Category -->
                  <div class="form-group row mb-3">
                    <label for="Category" class="col-md-4 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">Category</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" name="category" placeholder="Enter Category" required formControlName="category">
                    </div>
                </div>

                <!-- Assets Sub-Category -->
                <div class="form-group row mb-3">
                  <label for="SubCategory" class="col-md-4 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">Sub-Category</label>
                  <div class="col-md-8">
                      <input type="text" class="form-control" name="subcategory" placeholder="Enter Sub-Category" required formControlName="subcategory">
                  </div>
              </div>
                 <!-- Assets Brand -->
                 <div class="form-group row mb-3">
                    <label for="assetBrand" class="col-md-4 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">Asset Brand</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" name="assetBrand" placeholder="Enter Category" required formControlName="assetBrand">
                    </div>
                </div>

                <!-- Assets Model-->
                <div class="form-group row mb-3">
                  <label for="assetModel" class="col-md-4 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">Asset Model</label>
                  <div class="col-md-8">
                      <input type="text" class="form-control" name="assetModel" placeholder="Enter Sub-Category" required formControlName="assetModel">
                  </div>
              </div>



                  <!-- AddedBy Field -->

                  <!-- <div class="form-group row mb-3">
                      <label for="city" class="col-md-4 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">AddedBy</label>
                      <div class="col-md-8">
                          <input type="text" class="form-control" name="addedby" placeholder="" required formControlName="addedby">
                      </div>
                  </div> -->

                  <div class="col-md-8 offset-md-2 d-flex justify-content-center">
                      <button type="submit" class="btn btn-primary" style="width: 120px; background-color: #18aedf; color: white; font-size: 17px;">Update</button>
                  </div>

              </form>
          </div>
  </div>
</div>



