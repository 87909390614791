<div class="service-details-container  m-4">
    <h2 class="service-title">Service Details</h2>
  
    <div class="service-info">
      <p><strong>Service Type:</strong> {{ data.serviceType }}</p>
      <p><strong>Qualification:</strong> {{ data.qualification }}</p>
      <p><strong>Experience:</strong> {{ data.experience }} years</p>
      <p><strong>Location:</strong> {{ data.pincode }}, {{ data.city }}</p>
    </div>

  
    <h3 class="subservice-title">Services</h3>
    <ul class="subservices-list">
      <li *ngFor="let subService of data.subServices" class="subservice-item">
        <!-- <mat-icon class="subservice-icon">check_circle</mat-icon> -->
        {{ subService?.serviceName }}
      </li>
    </ul>
  
    <div class="actions">
      <button mat-raised-button color="primary" (click)="close()">Close</button>
    </div>
  </div>
  