<div class="container mt-4">
  <div class="row">
      <div class="col-md-6">
          <img src="assets/images/bu.png" class="img-fluid" alt="Your Image">
          <div class="col-md-12 col-xs-12 col-sm-12 res3">
    <ul class="sellCarInfolist dotted-list">

       <li>
         <div>
           <h4>Genuine Buyers</h4>
           <p>We let the buyers get in touch with you directly</p>
         </div>
       </li>

       <br>
       <li>
         <div >
           <h2>Your Homes/Rentals stays listed till the Building Materials is sold</h2>
         </div>
       </li>
     </ul>
                </div>
      </div>
      <div *ngIf="!isotp" class="card col-md-6 login-form">
          <h3 class="aucformTitle text-center">FORGOT PASSWORD</h3>
          <div class="text-center">
            <a style="cursor: pointer;" routerLink="/login">
              <i class="fa fa-arrow-left"></i> Go Back
            </a>
          </div>
          <form [formGroup]="formData" >

            <div class="aucformFieldsBox col-md-12 col-xs-12" style=" padding: 0 0;">
              <div class="col-md-12 col-sm-12">
              <label style="width: 100%;font-size: 17px;color: #020202; font-family: sans-serif;">Email/Mobile<span style="color: red">*</span></label>
            <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-user"></i></span>
            <input class="form-control" type="text" placeholder="Enter Email/Mobile" name="contact" formControlName="contact" required>
              </div>
              </div>
            </div>

            
            <div class="aucformFieldsBox col-md-12 col-xs-12 text-center">
              <h4>
                <button class="btn btn-primary" type="submit" (click)="forgotPassword()">FORGOT PASSWORD</button>
              </h4>
            </div>

              <!-- <button class="btn btn-primary"  type="submit">Submit</button> -->
          </form>

      </div>
      <div *ngIf="isotp" class="card col-md-6 login-form">
        <h3 class="aucformTitle text-center">FORGOT PASSWORD</h3>
        <div class="text-center">
          <a style="cursor: pointer;" routerLink="/login">
            <i class="fa fa-arrow-left"></i> Go Back
          </a>
        </div>
        <form  [formGroup]="formData1">

          <div class="aucformFieldsBox col-md-12 col-xs-12" style=" padding: 0 0;">
            <div class="col-md-12 col-sm-12">
              <label style="width: 100%;font-size: 17px;color: #020202; font-family: sans-serif;">Mobile/Email</label>
            <div class="input-group">
            <input class="form-control" type="text" value={{formData.value.contact}}>
              </div>
              </div>
            <div class="col-md-12 col-sm-12 mt-2">
            <label style="width: 100%;font-size: 17px;color: #020202; font-family: sans-serif;">OTP<span style="color: red">*</span></label>
          <div class="input-group">
          <input class="form-control" type="text" placeholder="Enter OTP" name="otp" formControlName="otp" required>
            </div>
            </div>
              <div class="col-md-12 col-sm-12 mt-2">
                <label style="width: 100%;font-size: 17px;color: #020202; font-family: sans-serif;">New Password<span style="color: red">*</span></label>
              <div class="input-group">
              <input [type]="isNewPasswordVisible ? 'text' : 'password'" class="form-control" name="newPass" placeholder="Enter New Password" required formControlName="newPass">
              <i class="fa position-absolute mt-1"
                 [ngClass]="{'fa-eye': !isNewPasswordVisible, 'fa-eye-slash': isNewPasswordVisible}"
                 (click)="toggleNewPasswordVisibility()"
                 style="top: 30%; right: 20px; transform: translateY(-50%); cursor: pointer;"
                 aria-hidden="true"></i>
                </div>
                </div>
                <div class="col-md-12 col-sm-12 mt-2">
                  <label style="width: 100%;font-size: 17px;color: #020202; font-family: sans-serif;">Confirm Password<span style="color: red">*</span></label>
                <div class="input-group">
                <input [type]="isConfirmPasswordVisible ? 'text' : 'password'" class="form-control" name="confPass" placeholder="Enter Confirm Password" required formControlName="confPass">
                <i class="fa position-absolute mt-1"
                   [ngClass]="{'fa-eye': !isConfirmPasswordVisible, 'fa-eye-slash': isConfirmPasswordVisible}"
                   (click)="toggleConfirmPasswordVisibility()"
                   style="top: 30%; right: 20px; transform: translateY(-50%); cursor: pointer;"
                   aria-hidden="true"></i>
                  </div>
                  </div>
          </div>

          
          <div  class="aucformFieldsBox col-md-12 col-xs-12 text-center">
            <h4>
              <button class="btn btn-primary" type="submit" (click)="ChangePassword()">Change Password</button>
            </h4>
          </div>
        </form>

    </div>
  </div>
</div>



