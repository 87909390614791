import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { DeleteAccountComponent } from '../delete-account/delete-account.component';


@Component({
  selector: 'app-sp-dashboard',
  templateUrl: './sp-dashboard.component.html',
  styleUrls: ['./sp-dashboard.component.css'],
})
export class SpDashboardComponent {

  isExpanded = true;
constructor(private dialog:MatDialog){}


  openPopup(){
    this.dialog.open(DeleteAccountComponent, {
      width: '400px',
      data: {
         
      }
    });
  }
}
