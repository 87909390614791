

<div class="container main-container" *ngIf="!editMode">
  <div class="card card-container">
      <div class="card-header">
          <h1>My Services</h1>
      </div>
  </div>
 
  <form [formGroup]="addServiceForm">
    <div class="form-group mt-2 col-md-4">
      <!-- <label for="subCategory">Select Service:</label> -->
      <select
        id="subCategory"
        name="subCategory"
        class="form-control"
        formControlName="subCategory"
        (change)="onServiceSelectionChange()"
      >
        <option value="" disabled selected>Select your service here</option>
        <option *ngFor="let category of subCategories" [value]="category">
          {{ category }}
        </option>
      </select>
    </div>
  </form>
 
   <!-- table -->
   <div class="table-container">

  <table class="table table-bordered table-striped mt-3">
    <div class="d-flex justify-content-center" *ngIf="bool">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
   <thead>
    <tr>
       <th scope="col">Srl._No.</th>
       <th scope="col">USER_SERVICES_ID</th>
       <th scope="col">SERVICE_TYPE</th>
       <th scope="col">QUALIFICATION</th>
       <th scope="col">EXPERIENCE</th>
       <th scope="col">AVAILABLE_WITHIN_RANGE</th>
       <th scope="col">LOCATION</th>
       <th scope="col">CITY</th>
       <th scope="col">EDIT</th>
    </tr>
   </thead>
<tbody>

<tr *ngFor="let item of myServices | paginate: {itemsPerPage:20,currentPage:page,totalItems:totalLength}; let i = index">

  <td>{{ i + 1 + (page - 1) * itemsPerPage }}</td>
  <td style="cursor: pointer;" (click)="openDetails(item)">{{ item.userServicesId }}</td>
  <td>{{ item.serviceType }}</td>
  <td>{{ item.qualification }}</td>
  <td>{{ item.experience}}</td>
  <td>{{ item.availableWithinRange }}</td>
  <td>{{ item.pincode }}</td>
  <td>{{ item.city }}</td>
  <td><button (click)="onEdit(item)">Edit</button></td>




</tr>
</tbody>

      </table>

      <div class="pagination">
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>

   </div>
</div>

<app-sp-update-service *ngIf="editMode" [data]="data" (back)="back($event)"></app-sp-update-service>


