



  <div class="dashboard-container d-flex ">
    <!-- Sidebar -->
    <div class="sidebar" style="width:15%;">
      <div class="accordion" id="sidebarAccordion" style="padding-top: 20px;">

          <div class="accordion-item p-3 dashboard-item" >
            <a class="nav-link " routerLink="/dashboard/dashboard-pannel">
              <i class="fas fa-fw fa-tachometer-alt"></i>
              <span >Dashboard</span></a>
          </div>
        <!-- Service Manager Accordion -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="serviceManagerHeading">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#serviceManagerCollapse" aria-expanded="true" aria-controls="serviceManagerCollapse">
              Admin
            </button>
          </h2>
          <div id="serviceManagerCollapse" class="accordion-collapse collapse show" aria-labelledby="serviceManagerHeading">
            <div class="accordion-body">

              <!-- Service Person Option -->
              <div class="d-block m-2">
              <a  routerLink="service-person-page">Service Person</a>
              </div>

              <!-- Service Request Option -->
              <div class="d-block m-2">
              <a routerLink="service-request-page">Service Request</a>
              </div>

              <div class="d-block m-2">
                <a href="/dashboard/" routerLink="add-asset-categories">Add Asset Categories</a>
              </div>

              <div class="d-block m-2">
                <a href="/dashboard/" routerLink="show-asset-categories">Show Asset Categories</a>
              </div>

              <div class="d-block m-2">
                <a href="/dashboard/" routerLink="add-service-category">Add service Categories</a>
              </div>

              <div class="d-block m-2">
                <a href="/dashboard/" routerLink="show-service-category">Show service Categories</a>
              </div>

              <div class="d-block m-2">
                <a href="/dashboard/" routerLink="admin-service-name">Add Service Name</a>
              </div>

              <div class="d-block m-2">
                <a href="/dashboard/" routerLink="show-service-names">Show Service Names</a>
              </div>

              <div class="d-block m-2">
                <a href="/dashboard/" routerLink="customer-report">Customer Report</a>
              </div>

              <div class="d-block m-2">
                <a href="/dashboard/" routerLink="show-service-charge">show Service Charge</a>
              </div>

              <div class="d-block m-2">
                <a href="/dashboard/" routerLink="apiUrl">Api Url</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="main-content body" >
      <router-outlet></router-outlet>
    </div>
  </div>


