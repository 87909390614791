<div class="row g-3 justify-content-around p-4 content" style="height:700px">
    <div class="col-md-3 sticky" style="height: 440px; margin-bottom: 100px; overflow-y: auto; position: relative;">
      <div *ngFor="let property of spServices; let i = index">
        <div 
          class=" border-0 shadow position-relative mb-2"
          [ngClass]="{'bg-light-green': fullDetail === property || (i === 0 && flag)}"
          (click)="selectProperty(property, i)"
          style="cursor: pointer;"
        >
          <div class="card-body">
            <h5 class="card-title heading-small fw-bold text-primary font">
              <i class="fas fa-user icon"></i> {{ property.name }}
            </h5>
            <div class="card-text">
              <div class="heading-small mb-2 fw-bold font" *ngIf="property.mobile" >
                Mobile: <span class="badge bg-secondary text-uppercase font">{{ property.mobile }}</span>
              </div>
              <div class="heading-small mb-2 fw-bold font" *ngIf="property.assetBrand">
                Asset Brand: <span class="badge bg-secondary text-uppercase font">{{ property.assetBrand }}</span>
              </div>
              <div class="heading-small fw-bold mb-2 font" *ngIf="property.serviceSubCategory">
                Service SubCategory: <span class="badge bg-secondary text-uppercase font">{{ property.serviceSubCategory }}</span>
              </div>
              <div class="heading-small fw-bold mb-2 font" *ngIf="property.assetModel">
                Rental Asset Model: <span class="badge bg-secondary text-uppercase font">{{ property.assetModel }}</span>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Full detail card for mobile devices -->
        <div *ngIf="isMobile && fullDetail && selectedIndex === i" class="card border-0 shadow card-custom mb-2">
          <div class="row">
            <div class="col-12">
              <img height="200" width="100%" src="https://static3.depositphotos.com/1000393/135/i/450/depositphotos_1350678-stock-photo-handyman-fun-view-from-above.jpg" class="card-img-top" alt="asset image">
            </div>
            <div class="col-12">
              <div class="card-body">
                <div class="card-text fw-bold">
                  <div class="heading-small mb-2 font">
                    <i class="fas fa-user icon"></i>Name : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.name }}</span>
                  </div>
                  <div class="heading-small mb-2 font">
                    <i class="fas fa-envelope icon"></i>Email : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.email }}</span>
                  </div>
                  <div class="heading-small mb-2 font">
                    <i class="fas fa-map-marker-alt icon"></i>Location : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.location }}</span>
                  </div>
                  <div class="heading-small mb-2 font">
                    <i class="fas fa-user-graduate icon"></i>Qualification : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.qualification }}</span>
                  </div>
                  <div class="heading-small mb-2 font">
                    <i class="fas fa-briefcase icon"></i>Experience : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.experience }} Years</span>
                  </div>
                  <div class="heading-small mb-2 font">
                    <i class="fas fa-check-circle icon"></i>Verified : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.verified }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-center">
              <button class="btn btn-primary  custom-btn">Book My Service</button>
              <button class="btn btn-primary custom-btn" style="margin-left:15px;">Rate My Service</button>
            </div>
            <div class="row fw-bold mt-3">
              <div class="col-12 text-center">
                <p class="mb-1 font">Available Service</p>
              </div>
            </div>
            <div class="mt-3 mx-auto" style="width: 100%;">
              <ul class="list-unstyled d-flex flex-wrap justify-content-center">
                <li *ngFor="let serviceName of fullDetail.serviceNames" class="service-name-item col-6 col-md-3">
                  <div class="badge bg-secondary text-uppercase text-center w-100 font">
                    {{ serviceName }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9">
      <div  class=" card shadow " *ngIf="fullDetail">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-2">
                <img 
                  src="https://th.bing.com/th/id/OIP.1F3FTn0OBXoHZu5Re11IzQHaEz?rs=1&pid=ImgDetMain" 
                  alt="Microsoft" 
                  class="me-2" 
                  style="border-radius: 50%; border: 4px solid #986de6" 
                  height="150" 
                  width="150" 
                />
              </div>
              
    
              <div class="col-12 col-md-3">
               
                <h3 style="margin-top: 15px; font-weight: bold;">{{ fullDetail.name |titlecase }}</h3>
                <div class="row" style="margin-top: -11px;">
                  <div class="col-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16" style="color: #986de6; margin-top: 10px;">
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                    </svg>
                  </div>
                  <div class="col-8 text-muted" >{{ fullDetail.location||fullDetail.availableLocation }}</div>
                </div>
                <br>
                <div class="row my-2"  *ngIf="fullDetail.delivery">
                  <div class="col-8">
                   <span>Delivery:</span>

                  </div>
                  <div class="col-4 text-muted" >{{ fullDetail.delivery }}</div>
                </div>
                <div class="row my-2"  *ngIf="fullDetail.delivery">
                  <div class="col-8">
                   <span>Pickup</span>
                  </div>
                  <div class="col-4 text-muted" >{{ fullDetail.pickup}}</div>
                </div>
                <div class="row my-2"  *ngIf="fullDetail.delivery">
                  <div class="col-8">
                   <span>Price per day</span>
                  </div>
                  <div class="col-4 text-muted" >{{ fullDetail.amountPerDay}}</div>
                </div>
                <div class="row my-2"  *ngIf="fullDetail.delivery">
                  <div class="col-8">
                   <span>Price per 30 day</span>
                  </div>
                  <div class="col-4 text-muted" >{{ fullDetail.amountPer30days}}</div>
                </div>
                <div class="row" *ngIf="fullDetail.email">
                  <div class="col-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" class="bi bi-envelope-arrow-up" viewBox="0 0 16 16" style="color:  #986de6; margin-top: 10px;">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4.5a.5.5 0 0 1-1 0V5.383l-7 4.2-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h5.5a.5.5 0 0 1 0 1H2a2 2 0 0 1-2-1.99zm1 7.105 4.708-2.897L1 5.383zM1 4v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1"/>
                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.354-5.354 1.25 1.25a.5.5 0 0 1-.708.708L13 12.207V14a.5.5 0 0 1-1 0v-1.717l-.28.305a.5.5 0 0 1-.737-.676l1.149-1.25a.5.5 0 0 1 .722-.016"/>
                    </svg>
                  </div>
                  <div class="col-8 text-muted" style="margin-top: 10px;">{{ fullDetail.email  }}</div>
                </div>
                <div class="row" *ngIf="fullDetail.mobile">
                  <div class="col-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" class="bi bi-telephone-outbound" viewBox="0 0 16 16" style="color:  #986de6; margin-top: 10px;">
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5"/>
                    </svg>
                  </div>
                  <div class="col-8 text-muted" style="margin-top: 10px;">{{ fullDetail.mobile }}</div>
                </div>            
              </div>
    
              <!-- <div class="col-12 col-md-1">
                <div class="vertical-line" style="margin-left: -49px;"></div>
              </div>
              <div class="col-12 col-md-3">
                <div class="circle-container" style="display: flex; justify-content: center; align-items: flex-start; gap: 35px; margin-right: 89px;">
                  
                  <div class="circle-wrapper" style="text-align: center;">
                    <p [ngStyle]="{ 'font-size': '15px'}" style="margin-bottom: -24px;">Overall review</p>
                    <div class="circle" style="background-color: #d5c8ee; color: darkblue;">
                      <span>4.5</span>
                    </div>
                    <p [ngStyle]="{'color': 'gold', 'font-size': '25px'}" style="margin-bottom: 5px;">★★★★★</p>
                    <p style="margin-top: 3px; font-size: 15px;">Based on 20 reviews</p>
                  </div>
                  
                  <div class="circle-wrapper" style="text-align: center;">
                    <p [ngStyle]="{ 'font-size': '15px'}" style="margin-bottom: -24px;">Total services</p>
                    <div class="circle" style="background-color: #d7ecd7; color: darkgreen;">
                      <span>100+</span>
                    </div>
                    <p style="margin-top: 3px; font-size: 15px;">More than 100 services</p>
                  </div>
                  <div class="circle-wrapper" style="text-align: center;">
                    <p [ngStyle]="{ 'font-size': '13px'}" style="margin-bottom: -24px;">Total Experience</p>
                    <div class="circle" style="background-color: #d7ecd7; color: darkgreen;">
                      <span>{{fullDetail.experience}}</span>
                    </div>
                    <p style="margin-top: 3px; font-size: 15px;">Years</p>
                  </div>
              
                </div>
              </div> -->
              
              
          
              <div class="col-12 col-md-1">
                <div class="vertical-line" style="margin-left: 31px;"></div>
            </div>
              
             
              <!-- <div class="col-12 col-md-3" >
               
                <h3 style="margin-top: 12px; font-weight: bold;">Bio</h3>
                    <p class="text-muted">
                      {{ isReadMore ? (bioText | slice: 0:100) + '...' : bioText }}
                      <span (click)="showText()" style="color: #986de6; cursor: pointer;">
                        {{ isReadMore ? 'Read More' : 'Read Less' }}
                      </span>
                    </p>
                 
              </div> -->
    
            </div>
          </div>
      
      </div>
      <!-- <div  class="row" style="margin-top: 20px;">
        <div class="col-12 col-md-6">
          <h1 style="font-size: 26px; font-weight: 600; margin-top: 15px;">Available Services</h1>
      
          <div *ngFor="let serviceName of fullDetail.serviceNames; let i = index" 
         class="card shadow" 
         [ngStyle]="{'background-color': getBackgroundColor(i)}" 
         style="margin-top: 35px;">
      <div class="card-body">
        <p style="font-size: 19px; font-weight: 500;">{{ serviceName }}</p>
      </div>
    </div>
    
      
         
        </div> -->
      
        <!-- <div class="col-12 col-md-3 offset-md-1 d-flex flex-column justify-content-start" style="margin-top: 88px;">
          <button type="button" class="btn btn-primary" style="width: 100%; height: 50px; font-size: 18px; font-weight: 500; margin-bottom: 43px;">
            Book My Service
          </button>
          <button type="button" class="btn btn-primary" style="width: 100%; height: 50px; font-size: 18px; font-weight: 500;">
            Rate My Service
          </button>
        </div> -->
      <!-- </div> -->
      
        </div>
      </div>
  
    <!-- Full detail card for desktop devices -->
    <!-- <div class="col-md-9 d-none d-md-block ">
      <div class="card border-0 shadow card-custom" *ngIf="fullDetail" style="height: 440px; margin-bottom: 100px; overflow-x: hidden;overflow-y:auto; position: relative;">
        <div class="row">
          <div class="col-md-4">
            <img height="200" width="400" src="https://static3.depositphotos.com/1000393/135/i/450/depositphotos_1350678-stock-photo-handyman-fun-view-from-above.jpg" class="card-img-top" alt="asset image">
          </div> -->
          <!-- <div class="col-md-6">
            <div class="card-body">
              <div class="card-text fw-bold">
                <div class="heading-small mb-2 font">
                  <i class="fas fa-user icon"></i>Name : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.name }}</span>
                </div>
                <div class="heading-small mb-2 font">
                  <i class="fas fa-envelope icon"></i>Email : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.email }}</span>
                </div>
                <div class="heading-small mb-2 font">
                  <i class="fas fa-map-marker-alt icon"></i>Location : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.location }}</span>
                </div>
                <div class="heading-small mb-2 font">
                  <i class="fas fa-user-graduate icon"></i>Qualification : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.qualification }}</span>
                </div>
                <div class="heading-small mb-2 font">
                  <i class="fas fa-briefcase icon"></i>Experience : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.experience }} Years</span>
                </div>
                <div class="heading-small mb-2 font">
                  <i class="fas fa-check-circle icon"></i>Verified : <span class="badge bg-secondary text-uppercase font">{{ fullDetail.verified }}</span>
                </div>
              </div>
            </div>
          </div> -->
         
        <!-- </div>
      </div>
    </div>
  </div> -->
  


  
  




