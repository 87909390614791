<footer class="mt-5 ">
<div class="container-fluid foo-bg">
  <div class="row pt-3">
    <p class="text-center" style="color: #fff;">*All product names, logos, and brands are property of their respective owners. All company, product and service names used in this website are for identification purposes only. Use of these names, logos, and brands does not imply endorsement.</p>
  </div>
  <div class="row">
  <div class="col-sm-4 col-md-4">
    <div class="footer-box address-inner" id="contact-section">
        <h2 style="color:#18aedf;margin-top:0px;font-weight:600;"> Me
        <span style="color: #fff;"> kanik</span>
      </h2>
      <div class="address">

        <i class="fa fa-address-card m-1" aria-hidden="true"></i>
        <span> Kosuri Engineers Pvt ltd,<br>Vikkirala pet road,
          Kandukuru,<br>Nellore Dist.,<br>Andhrapradesh. 523105.<br>
          India
        </span>
      </div>
      <div class="address">
        <i class="fa fa-phone m-1" aria-hidden="true"></i>
        <span>8919613326</span>
      </div>
      <div class="address">
        <i class="fa fa-envelope m-1" aria-hidden="true"></i>
        <span >info@kosuriers.com</span>
      </div>
    </div>

  </div>
  <div class="col-sm-8 col-md-8 ">
    <div class="row">
    <div class="col-md-4 col-sm-4">
    <div class="footer-box">
    <h4 class="footer-title" style="color: #fff;">Information</h4>
    <ul class="categoty white1">
    <li><a href="#" style="color: #fff;">About us</a></li>
    <li><a href="#" style="color: #fff;">Online Enquiry</a></li>
    <li><a href="#" style="color: #fff;">Call us</a></li>
    <li><a  style="color: #fff;" (click)="nav('/terms-conditions')">Terms and Conditions</a></li>
    <li><a  style="color: #fff;"  (click)="nav('/privacy')">Privacy &amp; Cookies Policy</a></li>
    <li><a href="#" style="color: #fff;">Become a partner</a></li>
    </ul>
    </div>
    </div>

    <div class="col-md-4 col-sm-4">
    <div class="footer-box">
    <h4 class="footer-title" style="color: #fff;">Our Parteners</h4>
    <ul class="categoty white1">
    <li><a target="_blank" href="http://carstand.in/" style="color: #fff;">Carstand</a></li>
    <li><a target="_blank" href="http://rxwala.com/" style="color: #fff;">rxwala</a></li>
    <!-- <li><a target="_blank" href="http://frytemate.com/index.jsp" style="color: #fff;">Frytemate</a></li>
    <li><a target="_blank" href="http://www.vaahanmitra.com/" style="color: #fff;">Vaahanmitra</a></li>
    <li><a target="_blank" href="http://www.zorocabs.com/" style="color: #fff;">Zorocabs</a></li> -->
    </ul>
    </div>
    </div>

    <!-- <div class="col-md-4 col-sm-4">
    <div class="footer-box">
    <h4 class="footer-title" style="color: #fff;">Links</h4>
    <ul class="categoty white1">
    <li><a href="#" style="color: #fff;"> Building Materials</a></li>
    <li><a href="#" style="color: #fff;"> Property Developers</a></li>
    <li><a href="#" style="color: #fff;"> Homes/Rental</a></li>
    <li><a href="#" style="color: #fff;">  Rentals</a></li>
    </ul>
    </div>
    </div> -->

     <div class="col-md-12 me-3" align="right" style="margin-top: 0px; margin-bottom: 10px;">
      <a
        class="btn text-white btn-floating m-3 extra-sty"
        style="background-color: #3b5998;"
        href="#!"
        role="button"
        ><i class="fab fa-facebook-f"></i
      ></a>
      <a
        class="btn text-white btn-floating m-3 extra-sty"
        style="background-color: #55acee;"
        href="#!"
        role="button"
        ><i class="fab fa-twitter"></i
      ></a>
      <a
        class="btn text-white btn-floating m-3 extra-sty"
        style="background-color: #0082ca;"
        href="#!"
        role="button"
        ><i class="fab fa-linkedin-in"></i
      ></a>
      <a
      class="btn text-white btn-floating m-3 extra-sty"
      style="background-color: #dd4b39;"
      href="#!"
      role="button"
      ><i class="fab fa-google"></i
    ></a>
    <a
      class="btn text-white btn-floating m-3 extra-sty"
      style="background-color: #FF0000;"
      href="#!"
      role="button"
      > <i class="fab fa-youtube"></i>
    </a>
    </div>
    </div>
 </div>
 </div>
</div>

  <div class="sub-footer ">
    <div class="container-fluid foo-bg1 pt-1" style="font-size: 12px;">
    <div class="row">
    <div class="col-sm-3">
    <p style="color:#fff;">Copyright © 2018 . All rights reserved </p>
    </div>

    <div class="col-sm-5">
    <p style="color:#fff;">Site Devloped &amp; Maintained By <a href="http://kosurisoft.com/" target="_blank" style=" color: #000;
    font-size: 17px; text-decoration: underline;">kosurisoft.com</a></p>
    </div>


     </div>
     </div>
   </div>
  </footer>
