<div class="container main-container">
  <div class="card card-container" style="height:270px">
    <div class="card-header">
      <h1>Customer Report</h1>
    </div>

    <div class="card-body border-right-primary border-left-primary">
      <form [formGroup]="customerRequestForm" (ngSubmit)="customerReportData()">
        <div class="form-row">
          <div class="col-md-3 form-group">
            <label for="userState" style="color: blue">State</label>
            <input
              type="text"
              class="form-control"
              id="userState"
              name="userState"
              formControlName="userState"
             

            />
          </div>
          <div class="col-md-3 form-group">
            <label for="fromDate" style="color: blue"
              >From Date</label
            >
            <input
              type="date"
              class="form-control"
              id="fromDate"
              name="fromDate"
              formControlName="fromDate"
            />
        </div>
        <div class="col-md-3 form-group">
          <label for="toDate" style="color: blue"
            >To Date</label
          >
          <input
            type="date"
            class="form-control"
            id="toDate"
            name="toDate"
            formControlName="toDate"
          />
      </div>
         
          <div class="col-md-3 form-group">
            <label for="request-status" style="color: blue">Phone Number</label>
            <input
              type="text"
              class="form-control"
              id="request-status"
              name="field3"
              formControlName="phoneNo"
            />
          </div>
          <div class="col-md-3 form-group">
            <label for="email" style="color: blue">Email-ID</label>
            <input
              type="email"
              class="form-control"
              id="email"
              name="email"
              formControlName="email"
            />
          </div>
        </div>

        <div class="form-row mt-3">
          <div class="col-md-12 text-center">
            <button type="submit" class="btn btn-primary">Search</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="table-container">
    <table class="table table-bordered table-striped mt-3">
      <div class="d-flex justify-content-center" *ngIf="bool">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <thead>
        <tr>
          <th>User Id</th>
          <th>Name</th>
          <th>Mobile</th>
          <th>Email</th>
          <th>City</th>
          <th>District</th>
          <th>State</th>
          <th>Location</th>
          <th>Registered Date</th>
        </tr>
      </thead>
      <tbody>
        <!-- Add table data here -->
        <tr
          *ngFor="
            let item of tableData
              | paginate
                : {
                    itemsPerPage: 20,
                    currentPage: page,
                    totalItems: totalLength
                  };
            let i = index
          "
        >
          <td>{{ item.userid }}</td>
          <td>{{ item.username }}</td>
          <td>{{ item.userMobile }}</td>
          <td>{{ item.userEmail }}</td>
          <td>{{ item.userTown }}</td>
          <td>{{ item.userDistrict }}</td>
          <td>{{ item.userState }}</td>
          <td>{{ item.userPincode }}</td>
          <td>{{ item.regDate | date }}</td>
          <!-- <td>{{ item.userid }}</td> -->
          <!-- <td> -->
            <!-- <button (click)="openAssetCategoryDialog(item.userid)">
              {{ item.userid }}
            </button> -->
            <!-- <a
              href="https://example.com/user-details/{{ item.userid }}"
              target="_blank"
              >{{ item.userid }}</a
            > -->
          <!-- </td> -->
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
</div>
