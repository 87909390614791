import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { userData } from '../../interfaces/user.modal';
import { ServiceRequest } from 'src/app/interfaces/service.modal';
import { ToastService } from '../toast/toast.service';
import { updateProfile } from 'src/app/interfaces/updateProfile.modal';
import { addAssetsData } from 'src/app/interfaces/addAssets.modal';
import { SessionTimeoutService } from '../sessionTimeout/session-timeout.service';
import { ServicePersonRegistration } from 'src/app/interfaces/ServicePersonRegistration.modal';
import { SpUpdatedProfile } from 'src/app/interfaces/spUpdateProfile.modal';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiserviceService {

  baseUrl = environment.apiUrl

  user_id = '';

  appKey = 'a0a7822c9b485c9a84ebcc2bae8c9ff4S';

  constructor(
    private http: HttpClient,
    private toast: ToastService,
    private sessionTimeoutService: SessionTimeoutService
  ) {


    this.initUserActivityListener();
  }

  getUrl(): Observable<{ status: boolean }> {
    return this.http.get("https://devapi.mrmason.in/getAdminUiEndPoint").pipe(
      map((response: any) => {
        if (response.success) {
          this.baseUrl = response.data[0].id.ipUrlToUi;
        } else {
          this.baseUrl = "https://devapi.mrmason.in";
        }
        return { status: true };
      }),
      catchError(error => {
        console.error('Error fetching base URL:', error);
        // Set a fallback base URL on error
        this.baseUrl = "https://devapi.mrmason.in";
        return of({ status: true });
      })
    );
  }

  deleteAccount(body: any) {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = `${this.baseUrl}/user/sp-delete-account`;
    return this.http.post(url, body, { headers });
  }

  getApiData() {
    return this.http.get("https://devapi.mrmason.in/getAdminUiEndPoint")
  }

  saveApiData(data: any) {
    const token = localStorage.getItem('jwtToken');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post("https://devapi.mrmason.in/addAdminUiEndPoint", data, { headers })
  }

  updateApiData(data: any) {
    const token = localStorage.getItem('jwtToken');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.put("https://devapi.mrmason.in/updateAdminUiEndPoint", data, { headers })
  }

  login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const data = {
      email: username,
      password: password,
      regSource: "MEKANIK"
    };
    return this.http.post(`${this.baseUrl}/login`, data, {
      headers: headers,
      responseType: 'json',
    });
  }

  adminLogin(
    username: string,
    password: string,
  ): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const data = {
      email: username,
      password: password,
      regSource: "MEKANIK"
    };

    return this.http.post(`${this.baseUrl}/adminLoginWithPass`, data, {
      headers: headers,
    });
  }

  spLogin(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // const data = JSON.stringify({
    //   email: username,
    //   password: password,
    // });
    const data = {
      email: username,
      password: password,
      regSource: "MEKANIK"
    };

    return this.http.post(`${this.baseUrl}/sp-login`, data, {
      headers: headers,
      responseType: 'json',
    });
  }
  register(userData: userData): Observable<any> {
    const data = {
      userEmail: userData.email,
      userMobile: userData.mobile,
      userPassword: userData.password,
      userName: userData.uName,
      userTown: userData.town,
      userDistrict: userData.district,
      userState: userData.state,
      userPincode: userData.pincode,
      userType: "EC",
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(`${this.baseUrl}/addNewUser`, data, {
      headers: headers,
    });
  }

  registerServicePerson(data: any): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(`${this.baseUrl}/sp-register`, data, {
      headers,
    });
  }

  //gettoken

  getToken() {
    return localStorage.getItem('jwtToken');
  }

  getServiceRequestData(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseUrl}/ServiceRequest/getFilteredReport?SERVICE_NAME=carpenter&LOCATION=kandu`
    );
  }

  // getServicePersonData(servicePerson: string, city: string): Observable<any[]> {
  //   return this.http.get<any[]>(`${this.baseUrl}/Staff/getFilteredReport?SERVICE_NAME=${servicePerson}&CITY=${city}&AVAILABLE_STATUS`);
  // }

  sendPasswordResetEmail(email: any): Observable<any> {
    return this.http.post<any[]>(
      `${this.baseUrl}/forgetPassword/sendOtp`,
      email,

    );
  }
  sendPasswordResetEmail1(email: any): Observable<any> {
    return this.http.post<any[]>(
      `${this.baseUrl}/forget-pwd-send-otp`,
      email,

    );
  }
  sendPasswordResetEmail2(email: any): Observable<any> {
    return this.http.post<any[]>(
      `${this.baseUrl}/admin/forgetPassword/sendOtp`,
      email,

    );
  }
  forgetverifyotp(data: any): Observable<any> {

    return this.http.post<any[]>(
      `${this.baseUrl}/forgetPassword/verifyOtpAndChangePassword`,
      data,

    );
  }
  forgetverifyotp1(data: any): Observable<any> {

    return this.http.post<any[]>(
      `${this.baseUrl}/forget-pwd-change`,
      data,

    );
  }
  forgetverifyotp2(data: any): Observable<any> {

    return this.http.post<any[]>(
      `${this.baseUrl}/admin/forgetPassword/verifyOtpAndChangePassword`,
      data,

    );
  }

  sendSubmitRequestData(
    serviceRequest: ServiceRequest,
  ): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any[]>(`${this.baseUrl}/addServiceRequest`, serviceRequest, {
      headers: headers,
    });
  }

  sendOtpByEmail(email: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const datanew = { email: email };
    return this.http.post<any>(`${this.baseUrl}/sendOtp`, datanew, {
      headers: headers,
    });
  }

  sendOtpByMobile(mobile: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const datanew = { mobile: mobile };
    return this.http.post<any>(`${this.baseUrl}/sendSmsOtp`, datanew, {
      headers: headers,
    });
  }

  sendEmailOtpForServicePerson(email: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(`${this.baseUrl}/sp-send-email-otp`, { contactDetail: email, regSource: "MEKANIK" }, {
      headers,
    });
  }

  sendMobileOtpForServicePerson(
    mobile: string,
  ): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.baseUrl}/sp-send-mobile-otp`, { contactDetail: mobile, regSource: "MEKANIK" }, {
      headers,
    });
  }

  verifyOtpByMobile(
    mobile: string,
    otp: string,

  ): Observable<any> {
    const apiUrl = this.baseUrl + '/verifySmsOtp';
    const data = {
      contactDetail: mobile,
      otp: otp,
      regSource: "MEKANIK"
    };
    return this.http.post<any>(apiUrl, data);
  }

  verifyOtpByEmail(
    email: string,
    otp: string,
  ): Observable<any> {
    const apiUrl = this.baseUrl + '/verifyOtp';
    const data = {
      contactDetail: email,
      otp: otp,
      regSource: "MEKANIK"
    };
    return this.http.post<any>(apiUrl, data);
  }



  verifyOtpByMobileForServicePerson(
    mobile: string,
    otp: string,
  ): Observable<any> {
    const apiUrl = this.baseUrl + '/sp-verify-mobile-otp';
    const data = {
      contactDetail: mobile,
      otp: otp,
      regSource: "MEKANIK"
    };
    return this.http.post<any>(apiUrl, data);
  }

  verifyOtpByEmailForServicePerson(
    email: string,
    otp: string,
  ): Observable<any> {
    const apiUrl = this.baseUrl + '/sp-verify-email-otp';
    const data = {
      contactDetail: email,
      otp: otp,
      regSource: "MEKANIK"
    };
    return this.http.post<any>(apiUrl, data);
  }

  getEcServiceRequestData(
    params: any
  ): Observable<any> {
    const url = `${this.baseUrl}/getServiceRequest`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(url, { headers, params });
  }
  getServiceCharge(
    params: any
  ): Observable<any> {
    const url = `${this.baseUrl}/getUserServiceCharegs`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(url, { headers, params });
  }

  getadminServiceRequestData(
    params: any
  ): Observable<any> {
    const url = `${this.baseUrl}/getServiceRequest`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(url, { headers, params });
  }

  getSpServiceRequestData(
    serviceName: string
  ): Observable<any> {
    const url = `${this.baseUrl}/getSpServiceRequest`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = { servicePersonId: serviceName };

    return this.http.get<any[]>(url, { headers, params });
  }

  getUserProfile(userId: string): Observable<any> {
    const token = localStorage.getItem('jwtToken');
    const body = localStorage.getItem('email');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams()
      .set('userid', userId)
      .set('regSource', "MEKANIK")
    const url = `${this.baseUrl}/getProfile`
    return this.http.get(url, { headers, params });
  }

  updateUserProfile(
    user_id: string,
    updatedProfile: updateProfile,
  ): Observable<any> {
    const token = localStorage.getItem('jwtToken');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = `${this.baseUrl}/updateProfile`;
    return this.http.put(url, updatedProfile, { headers });
  }
  addCharge(
    data: any
  ): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = `${this.baseUrl}/adding-UserServiceCharges`;
    const requestBody = {
      ...data,
    };

    return this.http.post(url, requestBody, { headers });
  }
  updateCharge(
    data: any
  ): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = `${this.baseUrl}/update-UserServiceCharges`;
    const requestBody = {
      ...data,
    };

    return this.http.put(url, requestBody, { headers });
  }

  addAsset(
    userId: string,
    data: addAssetsData
  ): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = `${this.baseUrl}/addAssets`;
    const requestBody = {
      userId: userId,
      ...data,
    };

    return this.http.post(url, requestBody, { headers });
  }

  addSpAsset(
    userId: string,
    data: addAssetsData
  ): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = `${this.baseUrl}/addSPAssets`;
    const requestBody = {
      userId: userId,
      ...data,
    };

    return this.http.post(url, requestBody, { headers });
  }

  getAssetData(params: any): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(
      `${this.baseUrl}/getAssets`,
      {
        headers, params
      }
    );
  }

  getSpAssetData(params: any): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(
      `${this.baseUrl}/getSPAssets`,
      {
        headers, params
      }
    );
  }


  getAssetDataById(appKey: string, userId: string, assetId: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(
      `${this.baseUrl}/get-asset.php?appKey=${appKey}&user_id=${userId}&asset_id=${assetId}`,
      {
        headers: headers,
      }
    );
  }

  editAsset(
    data: any
  ): Observable<any> {
    // const url = `${this.baseUrl}/update-profile?appKey=${appkey}&&asset_id=${assetId}&&category=${data.category}&&subcategory=${data.subcategory}&&location=${data.location}&&street=${data.street}&&door_no=${data.door_no}&&town=${data.town}&&district=${data.district}&&state=${data.state}&&pin_code=${data.pin_code}`

    const url = `${this.baseUrl}/updateAssets`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);


    return this.http.put(url, data, { headers });
  }

  editSpAsset(
    data: any
  ): Observable<any> {
    // const url = `${this.baseUrl}/update-profile?appKey=${appkey}&&asset_id=${assetId}&&category=${data.category}&&subcategory=${data.subcategory}&&location=${data.location}&&street=${data.street}&&door_no=${data.door_no}&&town=${data.town}&&district=${data.district}&&state=${data.state}&&pin_code=${data.pin_code}`

    const url = `${this.baseUrl}/updateSPAsset`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);


    return this.http.put(url, data, { headers });
  }
  addRental(
    data: any
  ) {

    const url = `${this.baseUrl}/addRentalData`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(url, data, { headers });
  }
  addSpRental(
    data: any
  ) {

    const url = `${this.baseUrl}/addServicePersonRentalData`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(url, data, { headers });
  }
  updateRental(
    data: any
  ) {

    const url = `${this.baseUrl}/updateRentalData`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.put(url, data, { headers });
  }
  updateSpRental(
    data: any
  ) {

    const url = `${this.baseUrl}/updateServicePersonAssetRentalCharge`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.put(url, data, { headers });
  }


  addAssetsCategory(data: any): Observable<any> {
    const url = `${this.baseUrl}/addAdminAssets`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const payload = {
      ...data,
    };
    return this.http.post(url, payload, { headers });
  }
  addServiceCategory(data: any): Observable<any> {
    const url = `${this.baseUrl}/addServiceCategory`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const payload = {
      ...data,
    };
    return this.http.post(url, payload, { headers });
  }

  getAcategory(): Observable<any> {
    const url = `${this.baseUrl}/getAdminAsset/civil/CIVIL`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(url, { headers });
  }
  getAcategory1(params: any): Observable<any> {
    const url = `${this.baseUrl}/getAdminAssets`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(url, { headers, params });
  }

  getScategory(): Observable<any> {
    let p: String = localStorage.getItem('serviceCategory') || 'Civil'
    const url = `${this.baseUrl}/getServiceCategory?serviceCategory=${p}`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = { serviceCategory: p };

    return this.http.get(url, { headers });
  }
  getServiceType(): Observable<any> {
    const url = `${this.baseUrl}/getServiceCategory/nonCivil/CIVIL`;
    // const token=this.getToken();
    // const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get(url);
  }
  getScategory1(params: any): Observable<any> {
    const url = `${this.baseUrl}/getServiceCategory`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);


    return this.http.get(url, { headers, params });
  }
  getRental(params: any): Observable<any> {
    const url = `${this.baseUrl}/getRentalData`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);


    return this.http.get(url, { headers, params });
  }

  getSpRental(params: any): Observable<any> {
    const url = `${this.baseUrl}/getServicePersonRentalAssets`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);


    return this.http.get(url, { headers, params });
  }

  getHomeRental(params: any): Observable<any> {
    const url = `${this.baseUrl}/getRentalAssetsNoAuth`;
    // const token=this.getToken();
    // const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);


    return this.http.get(url, { params });
  }

  getAssetCategoryDetails(assetId: string): Observable<any> {
    const url = `${this.baseUrl}/getAdminAssets`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const params = {
      assetId: assetId,
    };

    return this.http.get(url, { headers, params });
  }

  getServiceCategoryDetails(assetId: string): Observable<any> {
    const url = `${this.baseUrl}/getServiceCategory`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const params = {
      id: assetId,
    };

    return this.http.get(url, { headers, params });
  }

  editAssetCategory(data: any): Observable<any> {
    const url = `${this.baseUrl}/updateAdminAssets`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);



    return this.http.put(url, data, { headers });
  }

  editServiceCategory(data: any): Observable<any> {
    const url = `${this.baseUrl}/updateServiceCategory`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);



    return this.http.put(url, data, { headers });
  }

  searchPerson(
    params: any
  ): Observable<any> {
    const url = `${this.baseUrl}/filterServicePerson`;

    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // const params = {
    //   status: status,
    //   fromDate: fromDate,
    //   toDate:toDate
    // };
    return this.http.get(url, { headers, params });
  }

  private initUserActivityListener(): void {
    document.addEventListener('mousemove', () => {
      this.sessionTimeoutService.onUserActivity();
    });

    document.addEventListener('keypress', () => {
      this.sessionTimeoutService.onUserActivity();
    });
  }

  // Inside ApiserviceService

  updatePassword(
    email: string,
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
  ): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const data = {
      email: email,
      oldPass: oldPassword,
      newPass: newPassword,
      confPass: confirmPassword,

    };

    return this.http.post(`${this.baseUrl}/changePassword`, data, {
      headers,
    });
  }

  servicePersonUpdatePassword(
    email: string,
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
  ): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const data = {
      email: email,
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      regSource: "MEKANIK",
    };

    return this.http.post(`${this.baseUrl}/change-password`, data, {
      headers,
      responseType: 'json',
    });
  }

  adminUpdatePassword(
    email: string,
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
    appKey: string
  ): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const data = {
      email: email,
      oldPwd: oldPassword,
      newPwd: newPassword,
      conPwd: confirmPassword,
      appKey: this.appKey,

      type: 'admin',
    };

    return this.http.post(`${this.baseUrl}/changepassword.php`, data, {
      headers,
      responseType: 'json',
    });
  }

  updateServicePersonStatus(
    spId: string,
    status: string,
    location?: string
  ): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const data = {
      bodSeqNo: spId,
      availability: status,
      address: location,
    };

    const url = `${this.baseUrl}/sp-update-avalability`; // Updated the URL using baseUrl

    return this.http.post(url, data, { headers, responseType: 'json' });
  }

  addServiceName(
    serviceId: string,
    subcategory: string,
    servName: string,
    addedBy: string
  ): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const data = {
      serviceId: serviceId,
      serviceName: servName,
      addedBy: addedBy,
      serviceSubCategory: subcategory,
    };

    const url = `${this.baseUrl}/addAdminService`;

    return this.http.post(url, data, { headers });
  }

  // Inside ApiserviceService
  getServiceNames(params: any): Observable<any> {
    const url = ` https://devapi.mrmason.in/getSpService`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // const params = new HttpParams()
    //   .set('serviceSubCat', servSubCat);
    return this.http.get(url, { headers, params });
  }

  // Inside ApiserviceService

  updateServiceName(data: any): Observable<any> {
    const url = `${this.baseUrl}/updateAdminService`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.put(url, data, { headers });
  }

  getServiceData(params: any): Observable<any> {
    const url = `${this.baseUrl}/getAdminService`;

    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(`${url}`, { headers, params });
  }

  // Inside ApiserviceService
  postSpServiceData(data: any): Observable<any> {

    const url = `${this.baseUrl}/addSpService`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const addData = {
      userId: localStorage.getItem('USER_ID'),
      // pincode: localStorage.getItem('PINCODE_NO'),
      city: localStorage.getItem('CITY'),
    };
    return this.http.post(url, { ...data, ...addData }, { headers });
  }

  putSpServicesData(data: any): Observable<any> {
    // const url = `${this.baseUrl}/userServicesUpdate`;
    const url = `${this.baseUrl}/updateSpService`;
    const token = this.getToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` });


    return this.http.put(url, data, { headers });
  }

  showMyServices(userId: string): Observable<any> {
    const url = `${this.baseUrl}/getSpService`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('serviceType', userId);

    return this.http.get(url, { headers, params });
  }
  getSpDetails(params: any): Observable<any> {
    // let params = new HttpParams()
    // .set('serviceType', serviceType)
    // .set('location',encodeURIComponent(Location));
    const url = `${this.baseUrl}/getServicePersonDetails?`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);


    return this.http.get(url, { headers, params });
  }

  showMyServicesBySubCategory(category: string): Observable<any> {
    const url = `${this.baseUrl}/user-services-get.php`;
    const appKey = this.appKey;
    const userId = localStorage.getItem('USER_ID') ?? "";
    const params = new HttpParams().set('appKey', appKey).set('user_id', userId).set('category', category);
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(url, { headers, params });
  }

  getSpUserServices(subCategory: string): Observable<any> {
    const appKey = this.appKey;
    const userId = localStorage.getItem('USER_ID') ?? "";
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = `${this.baseUrl}/sp-user-services-get.php`;
    const params = new HttpParams()
      .set('appKey', appKey)
      .set('user_id', userId)
      .set('sub_cat', subCategory);

    return this.http.get(url, { headers, params });
  }

  getSpUserServicesName(params: any): Observable<any> {
    const appKey = this.appKey;
    const userId = localStorage.getItem('USER_ID') ?? "";
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = `${this.baseUrl}/sp-user-services-get`;
    // const params = new HttpParams()
    // .set('appKey', appKey)
    // .set('user_id', userId)
    // .set('sub_cat',subCategory);

    return this.http.get(url, { headers, params });
  }
  // Inside ApiserviceService

  getEcUserProfileData(user_id: string): Observable<any> {
    const url = `${this.baseUrl}/getProfile`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams()
      .set('userid', user_id)

    return this.http.get(url, { headers, params });
  }

  // Inside ApiserviceService

  getSpUserProfile(userId: string): Observable<any> {
    const token = localStorage.getItem('jwtToken');
    const body: any = localStorage.getItem('USER_ID');
    let params = new HttpParams();

    params = params.append('bodSeqNo', body);
    params = params.append('regSource', 'MEKANIK')
    console.log(this.baseUrl, "SSSS")
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = `${this.baseUrl}/sp-get-profile`;


    return this.http.request('GET', url, { headers, params });
  }

  // Inside ApiserviceService

  spUpdateUserProfile(
    user_id: string,
    updatedProfile: SpUpdatedProfile,
  ): Observable<any> {
    const url = `${this.baseUrl}/sp-update-profile`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.put(url, {
      name: updatedProfile.spName,
      bodSeqNo: localStorage.getItem('USER_ID'),
      address: updatedProfile.address,
      city: updatedProfile.city,
      state: updatedProfile.state,
      district: updatedProfile.district,
      pincodeNo: updatedProfile.pincode,
    }, { headers });
  }

  addSpUserServices(
    serviceIds: any[],
    subCategory: string,

  ): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const url = `${this.baseUrl}/add-service`;
    const data = {
      bodSeqNo: localStorage.getItem('USER_ID'),
      serviceSubCategory: subCategory,
      serviceId: serviceIds.join(", "),
      updatedBy: localStorage.getItem('USER_ID'),
    };

    return this.http.post(url, data, { headers });
  }

  updateSpUserServices(
    selectedServices: string[],
    subCategory: string
  ): Observable<any> {
    const url = `${this.baseUrl}/sp-user-services-update.php`;
    const data = {
      appKey: this.appKey,
      userId: localStorage.getItem('USER_ID'),
      subcategory: subCategory,
      service_id: selectedServices,
    };

    return this.http.put(url, data);
  }

  searchCustomer(
    params: any
  ): Observable<any> {
    const url = `${this.baseUrl}/filterCustomers`;

    // Set up headers
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(url, { headers, params });
  }

  searchCustomerAssets(
    userId?: string | null,
    category?: string | null,
    subCategory?: string | null,
    location?: string | null,

  ): Observable<any> {
    const url = `${this.baseUrl}/getAssets`;

    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    // Set up params
    const params = new HttpParams()
      .set('userId', userId || '');

    // Make the GET request
    return this.http.get(url, { headers, params });
  }

  // ...
}
