

<div class="container mt-4">
  <div class="row">
    <div class="col-md-5">
      <img src="assets/images/bu.png" class="img-fluid" alt="Your Image">
      <div class="col-md-12 col-xs-12 col-sm-12 res3">
<ul class="sellCarInfolist dotted-list">

   <li>
     <div>
       <h4>Genuine Buyers</h4>
       <p>We let the buyers get in touch with you directly</p>
     </div>
   </li>

   <br>
   <li>
     <div >
       <h2>Your Homes/Rentals stays listed till the Building Materials is sold</h2>
     </div>
   </li>
 </ul>
            </div>
    </div>
    <div class="col-md-7 login-form card">
            <div class="auctionFormRight" style="margin-top: 0px;">
                <h3 class="aucformTitle text-center">Please verfiy your Email and Mobile Number</h3>
                <form [formGroup]="verifyForm" (ngSubmit)="verifySubmit()">

                    <!-- Mobile Number Field -->
                    <div class="form-group row mb-3">
                      <label for="mobileNo" class="col-md-2 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">Mobile<span style="color: red">*</span></label>

                      <div class="col-md-4 d-flex align-items-center">
                          <input type="text" class="form-control" name="mobileNo" placeholder="Enter Your Mobile_No" pattern="[1-9]{1}[0-9]{9}" title="Enter 10-digit mobile number" maxlength="10" formControlName="mobile" required>
                      </div>

                      <div class="col d-flex align-items-center">
                          <button type="button" class="btn btn-warning btn-block wide-button" (click)="sendMobileOTP()">Send OTP</button>
                      </div>

                      <div class="col d-flex align-items-center">
                          <input type="text" class="form-control" name="enterOTP" placeholder="Enter OTP" formControlName="enterMobileOTP">
                      </div>

                      <div class="col d-flex align-items-center ">
                          <button type="button" class="btn btn-success btn-block wide-button" (click)="verifyMobileOTP()">Verify</button>
                      </div>
                    </div>

                    <!-- Email ID Field -->
                    <div class="form-group row mb-3">
                        <label for="emailId" class="col-md-2 control-label" style="font-size: 17px; color: #020202; font-family: sans-serif;">Email ID<span style="color: red">*</span></label>
                        <div class="col-md-4 d-flex align-items-center">
                            <input type="text" class="form-control" name="emailId" placeholder="Enter Your Email_Id" required formControlName="email">
                        </div>
                        <div class="col">
                          <button type="button" class="btn btn-warning btn-block wide-button" (click)="sendEmailOTP()">Send OTP</button>
                      </div>
                      <div class="col">
                          <input type="text" class="form-control" name="enterOTP" placeholder="Enter OTP" formControlName="enterEmailOTP">
                      </div>
                      <div class="col">
                          <button type="button" class="btn btn-success btn-block wide-button" (click)="verifyEmailOTP()" >Verify</button>
                      </div>
                    </div>
                    <!-- Register Button -->
                    <div class="form-group row mb-3">
                        <div class="col-md-10 offset-md-2">
                            <button type="submit" class="btn btn-primary" style="width: 120px; background-color: #18aedf; color: white; font-size: 17px;" routerLink="/login" [disabled]="!isLoginEnabled()">Login</button>
                        </div>
                    </div>
                </form>
            </div>
    </div>
  </div>


</div>
