<!-- src/app/password-reset/password-reset.component.html -->
<div class="form-container mt-3">
  <h2 class="aucformTitle text-center">RESET PASSWORD</h2>
  <form [formGroup]="updateForm" (ngSubmit)="updateSubmitForm()">
    <div class="form-group position-relative">
      <input
        [type]="isOldPasswordVisible ? 'text' : 'password'"
        id="oldPassword"
        name="oldPassword"
        class="form-control"
        placeholder="Enter Your Old Password"
        formControlName="oldPassword"
      />
      <i class="fa position-absolute"
         [ngClass]="{'fa-eye': !isOldPasswordVisible, 'fa-eye-slash': isOldPasswordVisible}"
         (click)="toggleOldPasswordVisibility()"
         style="top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;"
         aria-hidden="true"></i>
    </div>

    <div class="form-group position-relative">
      <input
        [type]="isNewPasswordVisible ? 'text' : 'password'"
        id="newPassword"
        name="newPassword"
        class="form-control"
        placeholder="Enter Your New Password"
        formControlName="newPassword"
      />
      <i class="fa position-absolute"
         [ngClass]="{'fa-eye': !isNewPasswordVisible, 'fa-eye-slash': isNewPasswordVisible}"
         (click)="toggleNewPasswordVisibility()"
         style="top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;"
         aria-hidden="true"></i>

      <div *ngIf="updateForm.get('newPassword')?.dirty" class="mt-2">
        <div
          [ngClass]="
            updateForm.get('newPassword')?.hasError('minlength')
              ? 'invalid'
              : 'valid'
          "
        >
          Must be at least 8 characters.
        </div>

        <div
          [ngClass]="
            updateForm.get('newPassword')?.hasError('atLeastOneLetter')
              ? 'invalid'
              : 'valid'
          "
        >
          Must include at least one letter.
        </div>

        <div
          [ngClass]="
            updateForm.get('newPassword')?.hasError('atLeastOneNumber')
              ? 'invalid'
              : 'valid'
          "
        >
          Must include at least one number.
        </div>
        <div
          [ngClass]="
            updateForm.get('newPassword')?.hasError('atLeastOneSpecialCharacter')
              ? 'invalid'
              : 'valid'
          "
        >
          Must include at least one special character (@#$%&*).
        </div>
      </div>
    </div>

    <div class="form-group position-relative">
      <input
        [type]="isConfirmPasswordVisible ? 'text' : 'password'"
        id="confirmPassword"
        name="confirmPassword"
        class="form-control"
        placeholder="Enter Your Confirm Password"
        formControlName="confirmPassword"
      />
      <i class="fa position-absolute"
         [ngClass]="{'fa-eye': !isConfirmPasswordVisible, 'fa-eye-slash': isConfirmPasswordVisible}"
         (click)="toggleConfirmPasswordVisibility()"
         style="top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;"
         aria-hidden="true"></i>
      <div
        *ngIf="updateForm.hasError('passwordMismatch')"
        class="validation-message val"
      >
        Passwords do not match.
      </div>
    </div>

    <button type="submit" class="resetButton" [disabled]="!updateForm.valid">
      UPDATE
    </button>
  </form>
</div>
