<div class="form-container">
  <h2 class="aucformTitle text-center">Update Availability</h2>
  <form [formGroup]="updateForm" (ngSubmit)="updateSubmitForm()">
    <div class="form-group">
      <label for="location">Location:</label>
      <input
        type="text"
        id="chargesInput"
        name="location"
        class="form-control"
        placeholder="Enter Your Location"
        #placesRef="ngx-places"
        ngx-gp-autocomplete
        [options]="options"
        (onAddressChange)="handleAddressChange($event)"
        formControlName="location"
      />
    </div>

    <div class="form-group">
      <label>Available Out of Station:</label>
      <div class="form-check">
        <input
          type="radio"
          id="availabilityYes"
          name="availability"
          class="form-check-input"
          formControlName="availability"
          value="yes"
        />
        <label class="form-check-label" for="availabilityYes">Yes</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          id="availabilityNo"
          name="availability"
          class="form-check-input"
          formControlName="availability"
          value="no"
        />
        <label class="form-check-label" for="availabilityNo">No</label>
      </div>
    </div>

    <button type="submit" class="btn btn-primary">Submit</button>
  </form>
</div>
